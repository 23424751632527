import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { IMG_URL } from '../../../api/config';
import { useDispatch } from 'react-redux';
import { useGlobalCompanyInfo } from '../../../utils/custom-hooks/common/useGlobalCompanyInfo';
import { addTheme } from '../../../style/addTheme';
import { useNavigate } from 'react-router';

const COMPANY_INFO_NAME: any = {
    "config_company_name": "회사명",
    "config_company_repre_name": "대표자",
    "config_business_number": "사업자등록번호",
    "config_company_address": "회사 주소",
    "config_private_info_name": "개인정보책임자",
    "config_private_info_email": "개인정보관리 책임자 이메일",
    "config_company_email": "이메일",
    "config_partner_company": "판매제휴업체",
    "config_online_prev_approve_code": "온라인 사전승낙서 코드",
    "config_company_call_number": "전화번호",
    "config_social_kakao_link": "카톡링크"
}

export default function FooterPart() {
    const companyInfo = useGlobalCompanyInfo();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <Wrap>
            <MaxWidthWrap>
                <LogoWrap>
                    <img src={'/img/asset/logo-o.png'}></img>
                </LogoWrap>
                <ContentWrap>
                    <LeftWrap>
                        <TextWrap>
                            <BasicText color='#333' fontSize={13}>통신판매업 신고: 제 2024-고양덕양구-2339 호</BasicText>
                            {/* <BasicText fontSize={14} color='#5c5c5c'>상호: ooo</BasicText>
                            <BasicText fontSize={14} color='#5c5c5c'>회사명: oooooo</BasicText>
                            <BasicText fontSize={14} color='#5c5c5c'>사업자번호: ooooooooooo</BasicText>
                            <BasicText fontSize={14} color='#5c5c5c'>대표자: ooo</BasicText>
                            <BasicText fontSize={14} color='#5c5c5c'>대표전화: ooooooooo</BasicText>
                            <BasicText fontSize={14} color='#5c5c5c'>개인정보책임자: ooo</BasicText>
                            <BasicText fontSize={14} color='#5c5c5c'>사업장 주소: oooooooooooo</BasicText> */}
                            <BasicText fontSize={14} color='#5c5c5c'>상호: {companyInfo['c_config_business_name']}</BasicText>
                            <BasicText fontSize={14} color='#5c5c5c'>회사명: {companyInfo['config_company_name']}</BasicText>
                            <BasicText fontSize={14} color='#5c5c5c'>사업자번호: {companyInfo['config_business_number']}</BasicText>
                            <BasicText fontSize={14} color='#5c5c5c'>대표자: {companyInfo['config_company_repre_name']}</BasicText>
                            <BasicText fontSize={14} color='#5c5c5c'>대표전화: {companyInfo['config_company_call_number']}</BasicText>
                            <BasicText fontSize={14} color='#5c5c5c'>개인정보책임자: {companyInfo['config_private_info_name']}</BasicText>
                            <BasicText fontSize={14} color='#5c5c5c'>사업장 주소: {companyInfo['config_company_address']}</BasicText>
                        </TextWrap>
                        <IconWrap>
                            <a href="https://www.ictmarket.or.kr:8443/precon/pop_CertIcon.do?PRECON_REQ_ID=PRE0000188734" target="_blank">
                                <img style={{cursor:"pointer",height:'120px'}} src="https://www.ictmarket.or.kr:8443/getCertIcon.do?cert_icon=KP24092705349B001" />
                            </a>
                        </IconWrap>
                    </LeftWrap>
                    <RightWrap>

                    </RightWrap>
                </ContentWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    background-color: #e5e7eb;
    padding: 45px 0;
`;

const LogoWrap = styled.div`
    height: 40px;
    &>img{
        height: 100%;
    }
`;

const ContentWrap = styled(RowBetween)`
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
    @media ${addTheme.media.sx}{
        margin-top: 30px;
    }
`;
const LeftWrap = styled(Row)`
    gap: 20px;
    width: auto;
    max-width: 50%;
    @media ${addTheme.media.m}{
        max-width: 100%;
    }
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
        &>div{
            width: 100%;
        }
    }
`;
const TextWrap = styled(Row)`
    width: auto;
    flex-wrap: wrap;
    column-gap: 20px;
`;
const IconWrap = styled(Row)`
    width: auto;
`;
const RightWrap = styled(Row)`
    width: auto;
    max-width: 50%;
`;