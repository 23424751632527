import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// type01
// import PhoneBannerPart from '../../type01/phone/part/PhoneBannerPart';
// import PhoneListPart from '../../type01/phone/part/PhoneListPart';
// import PhoneReviewPart from '../../type01/phone/part/PhoneReviewPart';

// type02
import PhoneBannerPart from '../../type02/phone/part/PhoneBannerPart';
import PhoneListPart from '../../type02/phone/part/PhoneListPart';
import PhoneUserListPart from '../../type02/phone/part/PhoneUserListPart';
import PhoneReviewPart from '../../type02/phone/part/PhoneReviewPart';
import PhoneBtnPart from '../../type02/phone/part/PhoneBtnPart';
import { useGlobalCompanyInfo } from '../../../utils/custom-hooks/common/useGlobalCompanyInfo';

export default function PhonePage() {
    const [btnState,setBtnState] = useState(false);
    const companyInfo = useGlobalCompanyInfo();
    
    useEffect(()=>{
        if(companyInfo.config_consultation_visible === '0'){
            setBtnState(false);
        }else{
            setBtnState(true);
        }
    },[companyInfo.config_consultation_visible]);

    return (
        <Wrap>
            <PhoneBannerPart />
            <PhoneListPart />
            <PhoneUserListPart />
            <PhoneReviewPart />
            {btnState &&
                <PhoneBtnPart /> 
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 0px 0 50px  ;
`;