import React from 'react'
import styled from 'styled-components'
import { Center, InputWrap, Row, RowBetween } from '../../../../style/basic/commonStyle';
import ResultPriceItem from '../item/ResultPriceItem';
import ResultMonthItem from '../item/ResultMonthItem';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { IMG_URL } from '../../../../api/config';
import { numberWithComma } from '../../../../utils/format/number';
import { useGlobalCompanyInfo } from '../../../../utils/custom-hooks/common/useGlobalCompanyInfo';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setGlobalRegister } from '../../../../store/common/globalRegister';

export default function ResultPart({id,phone_is_revervation,price,discountType,discount,imDiscount,payment,buyType,cardType,userPrice,setUserPrice}:any) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const company = useGlobalCompanyInfo();
    const cardDiscount = (cardType?.item?.dis_card_month_count ?? 0) * (cardType?.item?.dis_card_month_price ?? 0);
    const allDiscount = Number(discount ?? 0) + cardDiscount + Number(imDiscount ?? 0) + Number(userPrice);
    const resultCal = discountType === '1' ? Number(price ?? 0) - Number(discount ?? 0) - Number(cardDiscount ?? 0) - Number(imDiscount ?? 0)
                                    : Number(price ?? 0) - Number(cardDiscount ?? 0) - Number(imDiscount ?? 0)
    const result = resultCal < 0 ? 0 : resultCal;
    const resResult = result - userPrice;

    const kakaoClick = () =>{
        const kakaoLink = company['config_social_kakao_link'];
        if(kakaoLink === undefined || kakaoLink === '') return;
        window.open(kakaoLink);
    }

    const registerPage = () =>{
        dispatch(setGlobalRegister({
            phone_id:id,
            result: result,
        }))
        navigate(`/register/${id}`);
    }

    const inquiryCreatePage = () =>{
        navigate(`/inquiryCreate`);
    }
    
    return (
        <Wrap>
            <TitleWrap>
                <TitleItem>
                    사과나무 혜택으로 더욱 더 저렴하게 😆
                </TitleItem>
            </TitleWrap>
            <ResultPriceItem price={price} discountType={discountType} discount={discount} cardDiscount={cardDiscount} imDiscount={imDiscount} result={result} userPrice={userPrice} setUserPrice={setUserPrice} resResult={resResult} />
            <ResultMonthItem buyType={buyType} payment={payment} result={result} resResult={resResult} discountType={discountType} />
            <TextItem>
                <BasicText lh={1.2} style={{flexShrink:0}}>※&nbsp;</BasicText>
                <div>
                    <BasicText lh={1.2}>사과나무에서 <span>총 {numberWithComma(allDiscount)} 원의 할인</span> 혜택을 받아</BasicText>
                    <BasicText lh={1.2}>휴대폰을 구매하실 수 있습니다.</BasicText>
                </div>
            </TextItem>
            <BtnWrap>
                <BtnTopWrap>
                    {/* <BtnLikeWrap><FaHeart color='#d3d3d3' size={30}/></BtnLikeWrap> */}
                    {phone_is_revervation === '1' ?
                        <BtnRegWrap onClick={inquiryCreatePage}> 1:1 문의</BtnRegWrap> :
                        <BtnRegWrap onClick={registerPage}>온라인 신청</BtnRegWrap>
                    }
                </BtnTopWrap>
                <BtnBottomWrap onClick={kakaoClick}>
                    <img src={IMG_URL+'/img/logo/kakao.png'} alt='kakao-log'></img>
                    카카오톡 상담
                </BtnBottomWrap>
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    border-top: 4px solid #424242;
    background-color: #fff;
    padding: 20px 15px;
`;

const TitleWrap = styled(Row)`
    margin-top: 5px;
`;

const TitleItem = styled.div`
    background-color: #232323;
    color: #fff;
    display: flex;
    padding: 6px 10px;
    margin-left: 15px;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        bottom: -18px;
        left: 20px;
        border-top: 11px solid #232323;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-bottom: 11px solid transparent;
    }
`;

const TextItem = styled(Row)`
    flex-wrap: wrap;
    align-items: start;
    margin-top: 10px;
    & span{
        color: ${addTheme.main};
    }
    &>div{
        width: calc(100% - 15px);
    }
`;

const BtnWrap = styled.div`
    border-top: 1px solid #000;
    margin-top: 12px;
    padding-top: 12px;
    &>div{
        cursor: pointer;
    }
`;

const BtnTopWrap = styled(RowBetween)`
    gap: 20px;
`;

const BtnLikeWrap = styled(Center)`
    width: 32%;
    height: 54px;
    background-color: #fff;
    border-radius: ${addTheme.round}px;
    border: 1px solid #d3d3d3;
`;
const BtnRegWrap = styled(Center)`
    /* width: 68%; */
    width: 100%;
    background-color: ${addTheme.main};
    font-size: 19px;
    color: #fff;
    height: 54px;
    border-radius: ${addTheme.round}px;
`;

const BtnBottomWrap = styled(Center)`
    margin-top: 12px;
    gap: 15px;
    background-color: #f2e135;
    font-size: 19px;
    color: #362121;
    border-radius: ${addTheme.round}px;
    height: 54px;
`;