export const addTheme = {
    media:{
        l:'(max-width: 1270px)',
        m:'(max-width: 992px)',
        s:'(max-width:768px)',
        sx:'(max-width:480px)',
    },
    maxWidth:'1280px',
    padding:'0 15px',
    headerHeight:58,
    headerMt:14,
    titleFontSize:40,
    mTitleFontSize:28,
    subText:{
        color:'#ababab',
        fontSize:19,
        mFontSize:15,
    },
    color:'#FF6E38',
    main:'#FF6E38',
    section_color:'#fff9fb',
    bottom: 20,
    mt: '70px',
    mFontSize: 15,
    round: 5,
    pb: 20,
    colorToRgba:(color:string,opacity:number)=>{
        if(color === undefined){
            return 'transparent'
        }
        if(color.length < 7){
            return color
        }
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    },
    adminColor: '#1e293b',
    admBtn1: '#ff7600',
    admBtn2: '#0063ff',
    admBtn3: '#13c481',
    admBtn4: '#ad63ff',
    moreColor: '#737373',
    moreBg: '#ededed',
    kakaoColor: '#fff200',
}