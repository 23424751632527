import { axiosConfigInstance } from "./config"

// 관리자 로그인
export const apiAdmLogin = async(admin_email:string,admin_password:string) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/account').post('',{
            method: 'adminLoginAccount',
            params: {
                admin_email,admin_password
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 비밀번호 변경
export const apiAdmPassChange = async(token:string,admin_id:any,admin_email:string,admin_password:string,admin_new_password:string,admin_confirm_password:string) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/account',token).post('',{
            method: 'adminChangePassword',
            params: {
                admin_id,admin_email,admin_password,admin_new_password,admin_confirm_password
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 총 건수 가져오기
export const apiAdmGetTotalCount = async(token:string,admin_id:any,part:'faq'|'review'|'product'|'order'|'inquiry'|'popup'|'partner') =>{
    try {
        const res = await axiosConfigInstance('/api/admin/stat',token).post('',{
            method: 'adminGetStatCount',
            params:{
                admin_id,part
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}
