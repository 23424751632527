import React from 'react'
import styled from 'styled-components'
import SelectItem from './SelectItem';
import { Center, Row } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';

const BUY_TYPE = [
    {"id":"1","name":"일시불","value":"1" ,"type":""},
    {"id":"2","name":"24개월","value":"24" ,"type":"인기"},
    {"id":"3","name":"30개월","value":"30" ,"type":""},
    {"id":"4","name":"36개월","value":"36" ,"type":""}
]

export default function SelectBuyTypeItem({action}:any) {
    const clickAction = (data:any) =>{
        action.set(data);
    }
    return (
        <SelectItem title='구매방법'>
            <Wrap>
                {BUY_TYPE === undefined || BUY_TYPE.length === 0 ?
                    <></> :
                    BUY_TYPE.map((data:any,index:any)=>{
                        return(
                            <ItemWrap key={`buyType-${index}`} state={data.value === action.get} onClick={()=>clickAction(data.value)}>
                                {data.name}
                                {data.type !== '' &&
                                    <TypeTitle>{data.type}</TypeTitle>
                                }
                            </ItemWrap>
                        )
                    })
                }
            </Wrap>
        </SelectItem>
    )
}

const Wrap = styled(Row)`
    gap: 15px;
    max-width: 400px;
`;

const ItemWrap = styled(Center)<{state:boolean}>`
    width: 25%;
    height: 52px;
    border: 1px solid ${({state}) => state ? '#000' : '#d3d3d3'};
    background-color: ${({state}) => state ? '#fff' : '#f4f4f4'};
    color: ${({state}) => state ? '#000' : '#777'};
    border-radius: ${addTheme.round}px;
    cursor: pointer;
    position: relative;
`;

const TypeTitle = styled(Center)`
    border-radius: 50%;
    background-color: #333;
    color: #fff;
    width: 28px;
    height: 28px;
    position: absolute;
    top: -10px;
    left: -10px;
    font-size: 11px;
    text-align: center;
`;