import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AdminHomePart from '../part/AdminHomePart';
import PartTitleItem from '../item/PartTitleItem';
import AdminHomeOrderPart from '../part/AdminHomeOrderPart';
import AdminHomeVisitorsPart from '../part/AdminHomeVisitorsPart';
import AdminHomeInquiryPart from '../part/AdminHomeInquiryPart';
import { useNavigate } from 'react-router';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import AdminHomeReviewPart from '../part/AdminHomeReviewPart';
import AdminHomeChartPart from '../part/AdminHomeChartPart';
import { apiAdmGetReview } from '../../../../api/api_adm_review';
import { apiAdmGetInquiry } from '../../../../api/api_adm_inquiry';
import { apiAdmGetOrderList } from '../../../../api/api_adm_order';
import AdminHomePhOrderPart from '../part/adminHomePhOrderPart';
import { apiAdmGetOrder } from '../../../../api/api_adm_ph_order';

export default function AdmHomePage() {
    const navigate = useNavigate();
    const adminUser = useAdminUser();
    const [orderList,setOrderList] = useState<any>([]);
    const [orderPhList,setOrderPhList] = useState<any>([]);
    const [inquiryList,setInquiryList] = useState<any>([]);
    const [reviewList,setReviewList] = useState<any>([]);

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getOrderList();
        getOrderPhList();
        getInquiry();
        getReview();
    },[adminUser.admin_id])

    const goTotal = (router:string) =>{
        navigate(router);
    }

    // 주문
    const getOrderList = async() =>{
        const res = await apiAdmGetOrderList(adminUser.admin_token,adminUser.admin_id,1,5,'');
        if(res.result){
            setOrderList(res.data);
        }
    }

    // 핸드폰 주문
    const getOrderPhList = async() =>{
        const res = await apiAdmGetOrder(adminUser.admin_token,adminUser.admin_id, 1, 5, '');
        setOrderPhList(res.data);
    }

    // 문의 리스트
    const getInquiry = async() =>{
        const res = await apiAdmGetInquiry(adminUser.admin_token,adminUser.admin_id,1,5,'0');
        if(res.result){
            setInquiryList(res.data);
        }
    }

    // 리뷰 리스트
    const getReview= async() =>{
        const res = await apiAdmGetReview(adminUser.admin_token,adminUser.admin_id,1,5);
        if(res.result){
            setReviewList(res.data);
        }
    }
    return (
        <Wrap>
            <Grid container={true} spacing={1}>
                {/* <Grid item md={6} xs={12}>
                    <AdminHomePart title={<PartTitleItem title='인터넷 주문현황' border={true} goTotal={()=>goTotal('/adm/order/1')}/>}>
                        <AdminHomeOrderPart list={orderList} />
                    </AdminHomePart>
                </Grid> */}
                <Grid item md={6} xs={12}>
                    <AdminHomePart title={<PartTitleItem title='핸드폰 주문현황' border={true} goTotal={()=>goTotal('/adm/ph/order/1')}/>}>
                        <AdminHomePhOrderPart list={orderPhList} />
                    </AdminHomePart>
                </Grid>
                <Grid item xs={12} md={6}>
                    <AdminHomePart title={<PartTitleItem title='방문자 및 누적 데이터' border={true} />}>
                        <AdminHomeVisitorsPart />
                    </AdminHomePart>
                </Grid>
                <Grid item xs={12} md={6}>
                    <AdminHomePart title={<PartTitleItem title='상품문의' border={true} goTotal={()=>goTotal('/adm/inquiry/1/1')}/>} >
                        <AdminHomeInquiryPart list={inquiryList} />
                    </AdminHomePart>
                </Grid>
                <Grid item xs={12} md={6}>
                    <AdminHomePart title={<PartTitleItem title='구매후기' border={true} goTotal={()=>goTotal('/adm/review/1')} />} >
                        <AdminHomeReviewPart list={reviewList} />
                    </AdminHomePart>
                </Grid>
                <Grid item xs={12} md={12}>
                    <AdminHomePart title={<PartTitleItem title='주문현황' type='chart'/> } >
                        <AdminHomeChartPart />
                    </AdminHomePart>
                </Grid>
            </Grid> 
        </Wrap>
    )
}

const Wrap = styled.div``;