import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AdmBtnItem, AdminWrap, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { apiAdmGetLink, apiAdmSetCounseling, apiAdmSetLinkUpdate } from '../../../../api/api_adm_link';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';

export default function AdmLinkPage() {
    const adminUser = useAdminUser();
    const [info,setInfo] = useState({
        "config_company_name": "",
        "config_company_repre_name": "",
        "config_business_number": "",
        "config_business_name": "",
        "config_company_address": "",
        "config_private_info_name": "",
        "config_private_info_email": "",
        "config_company_email": "",
        "config_partner_company": "",
        "config_online_prev_approve_code": "",
        "config_company_call_number": "",
        "config_social_kakao_link": "",
        "config_social_youtube_link": "",
        "config_social_instagram_link": "",
        "config_consultation_visible": "",
    });

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getInfo();
    },[adminUser.admin_id])

    // link 정보 가져오기
    const getInfo = async() =>{
        const res = await apiAdmGetLink(adminUser.admin_token,adminUser.admin_id);
        if(res.result){
            setInfo({...res.data});
        }
    }

    // 수정
    const updateAction = async() =>{
        if(window.confirm('수정 하시겠습니까?')){
            const res = await apiAdmSetLinkUpdate(adminUser.admin_token,adminUser.admin_id,info);
            if(res.result){
                alert('수정되었습니다.');
            }else{
                alert(res.error);
            }
        }
    }

    // input 수정
    const infoChange = (name:string,val:any) =>{
        setInfo((obj:any)=>({
            ...obj,
            [name]: val,
        }))
    }

    // 상담신청 활성화/비활성화
    const changeCounseling = async(state:any) =>{
        let stateStr = state === '0' ? '비활성화' : '활성화'
        if(window.confirm(`${stateStr} 하시겠습니까?`)){
            const res = await apiAdmSetCounseling(adminUser.admin_token,adminUser.admin_id,state);
            if(res.result){
                alert(`${stateStr}로 변경되었습니다.`);
                getInfo();
            }
        }
    }

    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>링크 관리</BasicText>
                <InnerWrap>
                    <ContentWrap>
                        <ItemWrap>
                            <BasicText>회사명</BasicText>
                            <InputWrap>
                                <input value={info.config_company_name} onChange={(e:any)=>infoChange('config_company_name',e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>상호명</BasicText>
                            <InputWrap>
                                <input value={info.config_business_name} onChange={(e:any)=>infoChange('config_business_name',e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>사업자번호</BasicText>
                            <InputWrap>
                                <input value={info.config_business_number} onChange={(e:any)=>infoChange('config_business_number',e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>대표자명</BasicText>
                            <InputWrap>
                                <input value={info.config_company_repre_name} onChange={(e:any)=>infoChange('config_company_repre_name',e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>대표전화</BasicText>
                            <InputWrap>
                                <input value={info.config_company_call_number} onChange={(e:any)=>infoChange('config_company_call_number',e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>사업장 주소</BasicText>
                            <InputWrap>
                                <input value={info.config_company_address} onChange={(e:any)=>infoChange('config_company_address',e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>개인정보관리 책임자</BasicText>
                            <InputWrap>
                                <input value={info.config_private_info_name} onChange={(e:any)=>infoChange('config_private_info_name',e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>개인정보관리 책임자 이메일</BasicText>
                            <InputWrap>
                                <input value={info.config_private_info_email} onChange={(e:any)=>infoChange('config_private_info_email',e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>담당자 이메일</BasicText>
                            <InputWrap>
                                <input value={info.config_company_email} onChange={(e:any)=>infoChange('config_company_email',e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>카톡 채널 링크</BasicText>
                            <InputWrap>
                                <input value={info.config_social_kakao_link} onChange={(e:any)=>infoChange('config_social_kakao_link',e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>유튜브 링크</BasicText>
                            <InputWrap>
                                <input value={info.config_social_youtube_link} onChange={(e:any)=>infoChange('config_social_youtube_link',e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>인스타그램 링크</BasicText>
                            <InputWrap>
                                <input value={info.config_social_instagram_link} onChange={(e:any)=>infoChange('config_social_instagram_link',e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>상담 신청</BasicText>
                            <div>
                                {info.config_consultation_visible === '1' ?
                                    <AdmBtnItem bg={addTheme.adminColor} onClick={()=>changeCounseling('0')}>활성화</AdmBtnItem> :
                                    <AdmBtnItem bg={addTheme.adminColor} onClick={()=>changeCounseling('1')}>비활성화</AdmBtnItem>
                                }
                            </div>
                        </ItemWrap>
                    </ContentWrap>
                    <Row style={{marginTop:'50px'}}>
                        <AdmBtnItem bg={addTheme.adminColor} padding='14px 28px' onClick={updateAction}>링크 수정</AdmBtnItem>
                    </Row>
                </InnerWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 580px;
    margin-top: 30px;
`;