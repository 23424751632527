import React from 'react'
import styled from 'styled-components'
import { Center, ColorItem, Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { ORDER_TYPE, TEL_LIST } from '../../../../data/payment';
import { DISCOUNT_TYPE } from '../../../../data/state';
import { numberWithComma } from '../../../../utils/format/number';

export default function RegisterPhoneInfoPart({info,data,type,monthResult}:any) {
    
    return (
        <Wrap>
            <ImgWrap>
                <img alt='phone' src={info?.phone_thumbnail ?? info?.phone_info?.phone_thumbnail}></img>
            </ImgWrap>
            <ContentWrap>
                <BasicText fontSize={18} fontWeight={700}>구매 기기 정보</BasicText>
                <ContentItemWrap>
                    <ContentItem>
                        <BasicText>모델명</BasicText>
                        <BasicText>{data?.model ?? info?.phone_info?.phone_model}</BasicText>
                    </ContentItem>
                    <ContentItem>
                        <BasicText>용량</BasicText>
                        <BasicText>{data?.size?.volume ?? info?.order_volume?.volume} {String(data?.size?.volume ?? info?.order_volume?.volume ?? '').length < 3 ? 'TB' : 'GB'}</BasicText>
                    </ContentItem>
                    <ContentItem>
                        <BasicText>색상</BasicText>
                        <Row style={{width:'auto',gap:'10px'}}>
                            <ColorItem color={data?.color?.color_hex ?? info?.order_color?.color_hex ?? '#000'} size={14} />
                            <BasicText>{data?.color?.color_name ?? info?.order_color?.color_name ?? ''}</BasicText>
                        </Row>
                    </ContentItem>
                </ContentItemWrap>
            </ContentWrap>
            <ContentWrap>
                <BasicText fontSize={18} fontWeight={700}>구매 통신사 정보</BasicText>
                <ContentItemWrap>
                    <ContentItem>
                        <BasicText>통신사</BasicText>
                        <BasicText>{data?.telco?.name ?? TEL_LIST[info?.telco_id ?? '1'].name }</BasicText>
                    </ContentItem>
                    <ContentItem>
                        <BasicText>가입유형</BasicText>
                        <BasicText>{ORDER_TYPE[data?.telco_type ?? info?.order_type ?? '1'] }</BasicText>
                    </ContentItem>
                    <ContentItem>
                        <BasicText>할인방법</BasicText>
                        <BasicText>{DISCOUNT_TYPE[data?.discount_type ?? info?.discount_type ?? '1']}</BasicText>
                    </ContentItem>
                    <ContentItem>
                        <BasicText>요금제</BasicText>
                        <BasicText>{data?.plan?.plan_name ?? info?.plan_name?.plan_name}</BasicText>
                    </ContentItem>
                    <ContentItem>
                        <BasicText>구매방법</BasicText>
                        <BasicText>{(data?.buyType === 0 || info?.payment_month === 0) ? '일시불' : `${data?.buyType ?? info?.payment_month}개월 할부`}</BasicText>
                    </ContentItem>
                </ContentItemWrap>
            </ContentWrap>
            {type === 'myPage' ? 
                <></> :
                <ContentWrap>
                    <ResWrap>
                        <BasicText color='#fff' fontSize={20} mFontSize={18}>최종 월 납부 금액</BasicText>
                        <BasicText color='#fff' fontSize={30} mFontSize={26} fontWeight={700} ls={2}>{numberWithComma(monthResult)}원</BasicText>
                    </ResWrap>
                </ContentWrap>
            }
        </Wrap>
    )
}

const Wrap = styled.div``;
const ImgWrap = styled(Center)`
    width: 100%;
    & img{
        width: 100%;
        max-width: 300px;
    }
`;

const ContentWrap = styled.div`
    margin-top: 18px;
`;

const ContentItemWrap = styled(Row)`
    margin-top: 10px;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
`;

const ContentItem = styled(RowBetween)``;
const ResWrap = styled(Row)`
    flex-direction: column;
    align-items: end;
    gap: 6px;
    padding: 20px 15px;
    background-color: ${addTheme.main};
    border-radius: 6px;
`;