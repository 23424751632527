import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Center, RowBetween } from '../../../../style/basic/commonStyle';
import RectItem from '../../../common/item/RectItem';
import BasicText from '../../../basic/BasicText';
import { numberWithComma } from '../../../../utils/format/number';
import { addTheme } from '../../../../style/addTheme';
import { apiAdmGetMonthPoint, apiAdmGetVisit } from '../../../../api/api_adm_home';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { apiAdmGetTotalCount } from '../../../../api/api_adm_login';

export default function AdminHomeVisitorsPart() {
    const adminUser = useAdminUser();
    const [visit,setVisit] = useState<any>({});
    const [partner,setPartner] = useState<any>(0);
    const [point,setPoint] = useState<any>();

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getVisit();
        getMonthPoint();
        getCount();
    },[adminUser.admin_id]);

    // 방문자수
    const getVisit = async() =>{
        const res = await apiAdmGetVisit(adminUser.admin_token,adminUser.admin_id);
        if(res.result){
            setVisit(res.data);
        }
    }
    
    // 포인트
    const getMonthPoint = async() =>{
        const res = await apiAdmGetMonthPoint(adminUser.admin_token,adminUser.admin_id);
        if(res.result){
            setPoint(res.data);
        }
    }

    // 파트너 수
    const getCount = async() =>{
        const res = await apiAdmGetTotalCount(adminUser.admin_token,adminUser.admin_id,'partner');
        if(res.result){
            setPartner(res.data.count);
        }
    }
    return (
        <Wrap>
            <ContentItem>
                <RectItem paddingBottom={100}>
                    <ContentInnerWrap>
                        <BasicText fontSize={24} mFontSize={18}>{numberWithComma(visit?.yesterday_visit_count ?? 0)}</BasicText>
                        <BasicText>어제</BasicText>
                    </ContentInnerWrap>
                </RectItem>
            </ContentItem>
            <ContentItem>
                <RectItem paddingBottom={100}>
                    <ContentInnerWrap>
                        <BasicText fontSize={24} mFontSize={18}>{numberWithComma(visit?.today_visit_count ?? 0)}</BasicText>
                        <BasicText>오늘</BasicText>
                    </ContentInnerWrap>
                </RectItem>
            </ContentItem>
            <ContentItem>
                <RectItem paddingBottom={100}>
                    <ContentInnerWrap>
                        <BasicText fontSize={24} mFontSize={18}>{numberWithComma(visit?.total_visit_count ?? 0)}</BasicText>
                        <BasicText>전체</BasicText>
                    </ContentInnerWrap>
                </RectItem>
            </ContentItem>
            {/* <ContentItem>
                <RectItem paddingBottom={50}>
                    <ContentInnerWrap>
                        <BasicText fontSize={24} mFontSize={18}>{numberWithComma(partner ?? 0)}</BasicText>
                        <BasicText>파트너 수</BasicText>
                    </ContentInnerWrap>
                </RectItem>
            </ContentItem>
            <ContentItem>
                <RectItem paddingBottom={50}>
                    <ContentInnerWrap>
                        <BasicText fontSize={24} mFontSize={18}>{numberWithComma(point?.send_point ?? 0)}</BasicText>
                        <BasicText>이번달 총 포인트</BasicText>
                    </ContentInnerWrap>
                </RectItem>
            </ContentItem>
            <ContentItem>
                <RectItem paddingBottom={50}>
                    <ContentInnerWrap>
                        <BasicText fontSize={24} mFontSize={18}>{numberWithComma(point?.withdrawal_point ?? 0)}</BasicText>
                        <BasicText>이번달 출금 포인트</BasicText>
                    </ContentInnerWrap>
                </RectItem>
            </ContentItem> */}
        </Wrap>
    )
}

const Wrap = styled(RowBetween)`
    gap: 20px;
    margin-top: 25px;
    flex-wrap: wrap;
    @media ${addTheme.media.m}{
        margin-top: 10px;
        flex-wrap: wrap;
        gap: 20px 0;
    }
`;

const ContentItem = styled(Center)`
    border: 1px solid #e0e7ff;
    border-radius: 15px;
    width: calc(30% - 15px);
    margin: 0 auto;
    @media ${addTheme.media.m}{
        width: 100%;
    }
`;

const ContentInnerWrap = styled(Center)`
    width: 100%;
    height: 100%;
    flex-direction: column;
`;
