import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { AdminWrap, BtnItem, Center, ContentWrap, InputWrap, ItemWrap, Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { Grid, MenuItem } from '@mui/material';
import { fileTypeRes, onlyNumber } from '../../../../utils/format/string';
import { numberWithComma } from '../../../../utils/format/number';
import { useNavigate, useParams } from 'react-router';
import { apiAdmGetPhProductDetail, apiAdmGetPlanPrice, apiAdmSetPhProductColorImgUpdate, apiAdmSetPhProductContentImgUpdate, apiAdmSetPhProductImgUpdate, apiAdmSetPhProductState, apiAdmSetPhProductUpdate, apiAdmSetPlanPrice, apiAmdSetPhProductReservation } from '../../../../api/api_adm_product';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { PRODUCT_STATE, PRODUCT_STATE_R } from '../../../../data/state';
import SelectItem from '../../../common/item/SelectItem';
import { TEL_LIST } from '../../../../data/payment';
import { ADMIN_IMG_URL } from '../../../../api/config';
import AdminProductReservationItem from '../item/AdminProductReservationItem';
import { addTheme } from '../../../../style/addTheme';

// 1 삼성, 2애플 폰타입

export default function AdminProductDetailPage() {
    const {id} = useParams();
    const adminUser = useAdminUser();
    const navigate = useNavigate();
    const [info,setInfo] = useState<any>();
    const [reservation,setReservation] = useState(0);
    const [colorImgList,setColorImgList] = useState<any>([]);
    const colorImgRef = useRef<any>(null);
    const [colorItem,setColorItem] = useState<any>();

    const thumbnailRef = useRef<any>(null);
    const [thumbnail,setThumbnail] = useState<any>(null);
    const [thumbnailName,setThumbnailName] = useState('');
    const [thumbnailPreview,setThumbnailPreview] = useState('');

    const contentRef = useRef<any>(null);
    const [content,setContent] = useState<any>(null);
    const [contentName,setContentName] = useState<any>('');
    const [contentPreview,setContentPreview] = useState('');

    const [tel,setTel] = useState<any>();
    const [telPriceList,setTelPriceList] = useState<any>([]);

    useEffect(()=>{
        if(id === undefined) {
            navigate(-1);
            return;
        };
        getInfo();
    },[id,adminUser.admin_token]);

    useEffect(()=>{
        if(adminUser.admin_token === '') return;
        if(info?.phone_code === undefined) return;
        if(tel === undefined) return;
        getPlan();
    },[info?.phone_code,adminUser.admin_token,tel])

    const getInfo = async() =>{
        const res = await apiAdmGetPhProductDetail(adminUser.admin_token,adminUser.admin_id,id);
        if(res.result){
            setInfo(res.data[0]);
            setReservation(res.data[0].phone_is_revervation);
            setContentPreview(res.data[0].phone_desc_image);
            setThumbnailPreview(res.data[0].phone_thumbnail);
            setColorImgList(res.data[0].phone_colors.map((data:any)=>({color_hex:data.color_hex,file:'',filePreview:`${res.data[0].product_path}product_image/product_phone_${id}_${data.color_hex.replace('#','')}.jpg`,})))
            setTel(res.data[0].phone_support_telco[0].telco_id);
        }
    }

    // 요금제 별 공시지원금
    const getPlan = async() =>{
        const res = await apiAdmGetPlanPrice(adminUser.admin_token,adminUser.admin_id,info?.phone_code,tel);
        if(res.result){
            setTelPriceList(res.data);
        }
    }

    // 활성 / 비활성
    const productStateClick = async(state:any) =>{
        const res = await apiAdmSetPhProductState(adminUser.admin_token,adminUser.admin_id,id,state);
        if(res.result){
            alert(`${PRODUCT_STATE[state]} 했습니다.`);
            getInfo();
        }else{
            alert(res.error);
        }
    }

    const itemChange = (name:string,val:any,idx?:any,idxName?:string) =>{
        if(info === undefined) return;
        if(idx === undefined || idxName === undefined){
            setInfo({
                ...info,
                [name]:val,
            });
        }else{
            const newList = info[name];
            newList[idx][idxName] = val;
            setInfo({
                ...info,
                [name]:newList,
            });
        }
    }

    const sizeChange = (idx:any,val:any) =>{
        if(info === undefined) return;
        const newList = info?.phone_volume_price.map((data:any,index:any)=>index === idx ? {...data,price:val} : data);
        setInfo({
            ...info,
            ['phone_volume_price']: newList,
        });
    }
    // 썸네일, 상품정보 이미지 선택
    const fileChange = (e:any,ref:any,setFile:any,setFilePreview:any,setFileName:any) =>{
        const file = e.target.files[0];
        if(file){
            const fileFormat = fileTypeRes(file.name);
            if(fileFormat === 'png' || fileFormat === 'jpg' || fileFormat === 'gif'){
                const preImg = URL.createObjectURL(file);
                setFileName(file.name);
                const reader = new FileReader();

                reader.onload = () => {
                    const result = reader.result;
                    setFile(result);
                };

                reader.readAsDataURL(file);
                setFilePreview(preImg);
            }else{
                alert('확장자가 png, jpg, gif인 이미지 파일만 가능합니다.');
                ref.current.value = null;
            }
        }
    }
    
    const handleImageError = (event:any) => {
        event.target.onerror = null; 
        event.target.src = '';
    };

    // 컬러 이미지 클릭
    const colorImgClick = (data:any) =>{
        colorImgRef?.current?.click();
        setColorItem(data);
    }

    // 컬러 이미지 변경
    const colorImgChange = (e:any) =>{
        const file = e.target.files[0];
        
        if(file){
            const fileFormat = fileTypeRes(file.name);
            
            if(fileFormat === 'png' || fileFormat === 'jpg'){
                const preImg = URL.createObjectURL(file);
                const reader = new FileReader();

                reader.onload = () => {
                    const result = reader.result;
                    setColorImgList((arr:any)=>arr.map((data:any)=>(data.color_hex === colorItem.color_hex ? {...data,file:result,filePreview:preImg} : data)))
                };

                reader.readAsDataURL(file);
            }else{
                alert('확장자가 png, jpg 이미지 파일만 가능합니다.');
                colorImgRef.current.value = null;
            }
        }
    }

    // 수정
    const btnAction = async() =>{
        const newDiscount = info.discount.map((data:any)=>({...data,discount_price_2:0}));
        const res = await apiAdmSetPhProductUpdate({token:adminUser.admin_token,admin_id:adminUser.admin_id,discount_price:newDiscount,...info});
        
        if(res.result){
            alert('수정 했습니다.');
        }else{
            alert(res.error);
        }
    }

    // 색상 이미지 변경
    const colorImgUpdate = async(color_hex:any) =>{
        const img = colorImgList.filter((data:any)=>data.color_hex === color_hex);
        
        if(img.length === 0) return;
        if(img[0].file === ''){
            alert('이미지를 선택해주세요');
            return;
        }
        const res = await apiAdmSetPhProductColorImgUpdate(adminUser.admin_token,adminUser.admin_id,info.phone_id,color_hex,img[0].file);
        if(res.result){
            alert('색상 이미지를 수정했습니다.');
        }else{
            alert(res.error);
        }
    }

    // 썸네일 수정
    const thumbnailUpdate = async() =>{
        const res = await apiAdmSetPhProductImgUpdate(adminUser.admin_token,adminUser.admin_id,info.phone_id,thumbnail)
        if(res.result){
            alert('썸네일을 수정했습니다.')
        }else{
            alert(res.error);
        }
    }

    // 상품 이미지 수정
    const ContentUpdate = async() =>{
        const res = await apiAdmSetPhProductContentImgUpdate(adminUser.admin_token,adminUser.admin_id,info.phone_id,content);
        if(res.result){
            alert('상품정보를 수정했습니다.')
        }else{
            alert(res.error);
        }
    }

    // 사전예약 변경
    const reservationChange = async(val:any) =>{
        const reservationRes = await apiAmdSetPhProductReservation(adminUser.admin_token,adminUser.admin_id,id,val);
        if(reservationRes.result){
            alert(`사전예약이 ${val == 0 ? '취소 ':''}되었습니다.`)
            setReservation(val);
        }
    }

    // 공시지원금 변경
    const planPriceChange = (code:string,type:string,val:string) =>{
        const item = telPriceList.filter((data:any)=>data.plan_code === code)[0];
        const newItem = {
            ...item,
            [type]: val,
        }
        setTelPriceList((arr:any)=>arr.map((data:any)=>data.plan_code === code ? newItem : data));
    }

    // 공시지원금 변경 버튼
    const planPriceChangeAction = async() =>{
        const res = await apiAdmSetPlanPrice(adminUser.admin_token,adminUser.admin_id,info?.phone_code,telPriceList);
        if(res.result){
            alert('공시지원금이 변경되었습니다');
        }
    }

    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>상품 관리</BasicText>
                <InnerWrap>
                    <Grid container={true} spacing={2}>
                        <Grid item md={6} xs={12}>
                            <ContentWrap>
                                <AdminProductReservationItem margin='0' value={reservation} setValue={reservationChange} />
                                <ItemWrap pWidth={120}>
                                    <BasicText>제조사</BasicText>
                                    <SelectItem value={info?.phone_type ?? '1'} setValue={(val:any)=>itemChange('phone_type',val)} none='no'>
                                        <MenuItem value={'1'}>삼성</MenuItem>
                                        <MenuItem value={'2'}>애플</MenuItem>
                                    </SelectItem>
                                </ItemWrap>
                                <ItemWrap pWidth={120}>
                                    <BasicText>모델명</BasicText>
                                    <InputWrap>
                                        <input value={info?.phone_model ?? ''} onChange={(e:any)=>itemChange('phone_model',e.target.value)}></input>
                                    </InputWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={120}>
                                    <BasicText>상품제목명</BasicText>
                                    <InputWrap>
                                        <input value={info?.phone_name ?? ''} onChange={(e:any)=>itemChange('phone_name',e.target.value)}></input>
                                    </InputWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={120} align='start'>
                                    <BasicText>용량</BasicText>
                                    <ContentWrap>
                                        {info?.phone_volume_price === undefined || info?.phone_volume_price.length === 0 ?
                                            <></> :
                                            info?.phone_volume_price.map((data:any,index:any)=>{
                                                return(
                                                    <ItemWrap gap='15px' key={`size-${index}`}>
                                                        <InputWrap >
                                                            <BasicText>{data.volume}</BasicText>
                                                        </InputWrap>
                                                        <InputWrap>
                                                            <input value={numberWithComma(data.price)} onChange={(e:any)=>{sizeChange(index,onlyNumber(e))}}/>
                                                        </InputWrap>
                                                    </ItemWrap>
                                                )
                                            })
                                        }
                                    </ContentWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={120} align='start'>
                                    <BasicText>색상</BasicText>
                                    <ContentWrap>
                                        {info?.phone_colors === undefined || info?.phone_colors.length === 0 ?
                                            <></> :
                                            info?.phone_colors.map((data:any,index:any)=>{
                                                const colorImg = colorImgList.filter((item:any)=>item.color_hex === data.color_hex);
                                                const {filePreview} = colorImg.length === 0 ? {filePreview:''}: colorImg[0];
                                                
                                                return(
                                                    <div style={{width:'100%'}} key={`color-${index}`}>
                                                        <ItemWrap gap='15px'>
                                                            <ColorItem bg={data.color_hex}></ColorItem>
                                                            <InputWrap>
                                                                <BasicText style={{cursor:'pointer'}} onClick={()=>colorImgClick(colorImg[0])}>{data.color_name}</BasicText>
                                                            </InputWrap>
                                                            <ImgBtnWrap onClick={()=>colorImgUpdate(data.color_hex)}>이미지 등록하기</ImgBtnWrap>
                                                        </ItemWrap>
                                                        <div style={{width:'100%'}}>
                                                            <ColorImgWrap>
                                                                <img src={filePreview} onError={handleImageError}></img>
                                                            </ColorImgWrap>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <input style={{display:'none'}} ref={colorImgRef} type='file' accept='.png,.jpg,.gif' onChange={(e:any)=>colorImgChange(e)}></input>
                                    </ContentWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={120}>
                                    <BasicText>가입통신사</BasicText>
                                    <CheckWrap>
                                        {info?.phone_support_telco === undefined || info?.phone_support_telco.length === 0 ?
                                            <></> :
                                            info?.phone_support_telco.map((data:any,index:any)=>{
                                                return(
                                                    <CheckItemWrap key={`tel-${index}`}>
                                                        <input type='radio' defaultChecked={true} readOnly></input> {TEL_LIST[data.telco_id].name}
                                                    </CheckItemWrap>
                                                )
                                            })
                                        }
                                    </CheckWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={120}>
                                    <BasicText>구매방법</BasicText>
                                    <CheckWrap>
                                        <CheckItemWrap>
                                            <input type='radio' readOnly defaultChecked={true}></input> 일시불
                                        </CheckItemWrap>
                                        <CheckItemWrap>
                                            <input type='radio' readOnly defaultChecked={true}></input> 24개월
                                        </CheckItemWrap>
                                        <CheckItemWrap>
                                            <input type='radio' readOnly defaultChecked={true}></input> 30개월
                                        </CheckItemWrap>
                                        <CheckItemWrap>
                                            <input type='radio' readOnly defaultChecked={true}></input> 36개월
                                        </CheckItemWrap>
                                    </CheckWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={120} align='start'>
                                    <BasicText>썸네일</BasicText>
                                    <div style={{width:'100%'}}>
                                        <ItemWrap gap='15px'>
                                            <InputWrap>
                                                <input style={{cursor:'pointer'}} value={thumbnailName ?? ''} onClick={()=>thumbnailRef?.current?.click()} placeholder='이미지 선택' readOnly/>
                                            </InputWrap>
                                            <ImgBtnWrap onClick={thumbnailUpdate}>이미지 등록하기</ImgBtnWrap>
                                        </ItemWrap>
                                        <input style={{display:'none'}} ref={thumbnailRef} type='file' accept='.png,.jpg,.gif' onChange={(e:any)=>fileChange(e,thumbnailRef,setThumbnail,setThumbnailPreview,setThumbnailName)}></input>
                                        <ImgWrap>
                                            {thumbnailPreview !== '' &&
                                                <img style={{maxWidth:'100%',maxHeight:'280px'}} src={thumbnailPreview}></img>
                                            }
                                        </ImgWrap>
                                    </div>
                                </ItemWrap>
                                <ItemWrap pWidth={120} align='start'>
                                    <BasicText>상품정보</BasicText>
                                    <div style={{width:'100%'}}>
                                        <ItemWrap gap='15px'>
                                            <InputWrap>
                                                <input style={{cursor:'pointer'}} value={contentName ?? ''} onClick={()=>contentRef?.current?.click()} placeholder='이미지 선택' readOnly/>
                                            </InputWrap>
                                            <ImgBtnWrap onClick={ContentUpdate}>이미지 등록하기</ImgBtnWrap>
                                        </ItemWrap>
                                        <input style={{display:'none'}} ref={contentRef} type='file' accept='.png,.jpg,.gif' onChange={(e:any)=>fileChange(e,contentRef,setContent,setContentPreview,setContentName)}></input>
                                        <ImgWrap>
                                            {contentPreview !== '' &&
                                                <img src={contentPreview}></img>
                                            }
                                        </ImgWrap>
                                    </div>
                                </ItemWrap>
                                <ItemWrap pWidth={120}>
                                    <BasicText>기본 텍스트</BasicText>
                                    <InputWrap>
                                        <input value={info?.phone_description ?? ''} onChange={(e:any)=>itemChange('phone_description',e.target.value)}></input>
                                    </InputWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={120}>
                                    <BasicText>선택약정 할인</BasicText>
                                    <InputWrap>
                                        <BasicText>요금제 기준 25%</BasicText>
                                    </InputWrap>
                                </ItemWrap>
                                <ItemWrap pWidth={120}>
                                    <BasicText>사과나무할인</BasicText>
                                    <InputWrap>
                                        <input value={numberWithComma(info?.phone_im_discount ?? 0)} onChange={(e:any)=>itemChange('phone_im_discount',onlyNumber(e))}></input>
                                    </InputWrap>
                                </ItemWrap>
                            </ContentWrap>
                            <BtnWrap>
                                <BtnItem bg='#ad63ff' onClick={()=>btnAction()}>상품정보변경</BtnItem>
                                <BtnItem bg='#ff9f00' onClick={()=>productStateClick(info?.phone_visible === '1' ? '0' : '1')}>판매 {PRODUCT_STATE_R[info?.phone_visible ?? '1']  }</BtnItem>
                            </BtnWrap>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <ContentWrap>
                                <BasicText>공시지원 할인</BasicText>
                                <ContentWrap>
                                    <TelTitleWrap>
                                        {info?.phone_support_telco.map((data:any,index:any)=>{
                                            return(
                                                <TelTitleItem key={`tel-${index}`} state={tel === data.telco_id} onClick={()=>setTel(data.telco_id)}>{data.telco_name}</TelTitleItem>
                                            )
                                        })}
                                    </TelTitleWrap>
                                    <TelContentWrap>
                                        <ItemWrap gap='15px'>
                                            <InputWrap style={{border:'none'}}>
                                                <BasicText>이름</BasicText>
                                            </InputWrap>
                                            <InputWrap style={{border:'none'}}>
                                                <BasicText>기기변경</BasicText>
                                            </InputWrap>
                                            <InputWrap style={{border:'none'}}>
                                                <BasicText>통신사이동</BasicText>
                                            </InputWrap>
                                        </ItemWrap>
                                        {telPriceList === undefined || telPriceList.length === 0 ?
                                            <></> :
                                            telPriceList.map((data:any,index:any)=>{
                                                return(
                                                    <ItemWrap gap='15px' key={`size-${index}`}>
                                                        <InputWrap style={{border:'none'}}>
                                                            <BasicText>{data.plan_name}</BasicText>
                                                        </InputWrap>
                                                        <InputWrap>
                                                            <input value={numberWithComma(data.discount_change_price)} onChange={(e:any)=>{planPriceChange(data.plan_code,'discount_change_price',onlyNumber(e))}}/>
                                                        </InputWrap>
                                                        <InputWrap>
                                                            <input value={numberWithComma(data.discount_new_price)} onChange={(e:any)=>{planPriceChange(data.plan_code,'discount_new_price',onlyNumber(e))}}/>
                                                        </InputWrap>
                                                    </ItemWrap>
                                                )
                                            })
                                        }
                                    </TelContentWrap>
                                </ContentWrap>
                            </ContentWrap>
                            <BtnWrap>
                                <BtnItem bg='#ad63ff' onClick={planPriceChangeAction}>{info?.phone_support_telco.filter((data:any)=>data.telco_id === tel)[0]?.telco_name ?? ''} 공시지원할인변경</BtnItem>
                            </BtnWrap>
                        </Grid>
                    </Grid>
                </InnerWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    margin-top: 30px;
`;

const BtnWrap = styled(Row)`
    gap: 15px;
    margin-top: 35px;
    flex-wrap: wrap;
`;

const ColorItem = styled.div<{bg:string}>`
    width: 30px;
    height: 30px;
    background-color: ${({bg}) => bg ?? '#fff'};
    flex-shrink: 0;
    border-radius: 6px;
`;

const ColorImgWrap = styled.div`
    margin-top: 5px;
    width: 80px;
    max-height: 80px;
    overflow: hidden;
    & img{
        max-width: 100%;
    }
`;

const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    flex-shrink: 0;
    max-width: 100%;
`;

const ImgWrap = styled.div`
    max-width: 340px;
    max-height: 280px;
    overflow-y: auto;
    & img{
        max-width: 100%;
    }
`;
const CheckWrap = styled(Row)`
    gap: 5px 15px;
    flex-wrap: wrap;
`;

const CheckItemWrap = styled.label`
    display: flex;
    align-items: center;
    gap: 4px;
`;

const TelTitleWrap = styled(RowBetween)``;
const TelTitleItem = styled(Center)<{state:any}>`
    width: 33%;
    border: 1px solid #ddd;
    padding: 8px 0;
    border-radius: 4px;
    cursor: pointer;
    background-color: ${({state}) => state ? addTheme.adminColor : '#fff'};
    color:  ${({state}) => state ? '#fff' : '#000'};;
`;

const TelContentWrap = styled(Row)`
    flex-direction: column;
    gap: 6px;
`;