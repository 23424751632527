import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MaxWidthWrap, Row } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import BasicText from '../../../basic/BasicText';
import SelectPart from '../part/SelectPart';
import ResultPart from '../part/ResultPart';
import ContentPart from '../part/ContentPart';
import { USE_CARRIER } from '../../../../data/data';
import { TEL_LIST } from '../../../../data/payment';
import { useNavigate, useParams } from 'react-router';
import { apiGetDiscount, apiGetDiscountCard, apiGetPhDetail } from '../../../../api/api_phone';
import { setGlobalRegister } from '../../../../store/common/globalRegister';
import { useDispatch } from 'react-redux';

export default function DetailPage() {
    const {id} = useParams();
    const navigate = useNavigate();
    const [info,setInfo] = useState<any>(); // 디테일에서 가져온 데이터
    const [size,setSize] = useState<any>(); // 용량
    const [price,setPrice] = useState('0'); // 출고가
    const [color,setColor] = useState<any>(); // 컬러
    const [use,setUse] = useState(USE_CARRIER[0]); // 사용중인 통신사
    const [nowUse,setNowUse] = useState<any>(); // 사용할 통신사
    const [paymentList,setPaymentList] = useState([]); // 요금제 리스트
    const [payment,setPayment] = useState<any>(); // 선택한 요금제
    const [discountType,setDiscountType] = useState('1'); // 할인 방법 1공시 / 2선택약정
    const [discount,setDiscount] = useState<any>(); // 공시 or 선택약정 가격
    const [buyType,setBuyType] = useState('24'); // 구매방법 (일시불,24,30,36)
    const [cardList,setCardList] = useState([]); // 카드 리스트
    const [cardType,setCardType] = useState<any>(); // 선택한 카드
    const [discountObj,setDiscountObj] = useState<any>(); // 공시지원금 api로 가져온 객체
    const [userPrice,setUserPrice] = useState(0); // 추가할인
    const dispatch = useDispatch();
    
    useEffect(()=>{
        if(info === undefined) return;
        dispatch(setGlobalRegister({
            model: info.phone_model,
            size: size,
            color: color,
            telco: nowUse,
            telco_type: use?.id == nowUse?.telco_id ? '1' : '2',
            discount_type: discountType,
            plan: payment,
            buyType: Number(buyType),
            price: price,
            imDiscount: Number(info.phone_im_discount),
            discount1: Number(discount ?? 0),
            discount2: Number((payment?.plan_price ?? 0)*0.25),
            cardDiscount: Number(cardType?.item?.dis_card_month_count ?? 0) * Number(cardType?.item?.dis_card_month_price ?? 0),
            card: cardType ?? undefined,
            discountObj: discountObj,
            userPrice: userPrice,
        }))
    },[info,size,color,price,use,nowUse,payment,discountType,discount,buyType,cardType,discountObj,userPrice])

    // 사이즈에 따른 기기값 변경
    useEffect(()=>{
        if(info === undefined) return;
        if(size === undefined){
            if(info?.phone_volume_price === undefined || info?.phone_volume_price.length === 0) return
            setPrice(info?.phone_volume_price[0]?.price);
        }else{
            setPrice(size.price)
        }
    },[size,info])

    useEffect(()=>{
        if(id === undefined){
            navigate('/');
            return;
        }
        getDetail();
    },[id]);

    // 요금제
    useEffect(()=>{
        if(id === undefined) return;
        if(nowUse?.telco_id === undefined) return;
        getPayment();
    },[id,nowUse?.telco_id]);

    // 공시지원금
    useEffect(()=>{
        if(nowUse?.telco_id === undefined || info?.phone_code === undefined, payment?.plan_code === undefined) return;
        getDiscount();
    },[nowUse?.telco_id,info?.phone_code,payment?.plan_code,buyType])

    const getDetail = async() =>{
        const res = await apiGetPhDetail('1',id);
        if(res.result && (res.data.length !== 0)){
            setInfo(res.data[0]);
            setData(res.data[0]);
        }
    }

    const getPayment = async() =>{
        const res = await apiGetDiscountCard(id,nowUse.telco_id);
        if(res.result){
            setPaymentList(res.data.plan_list);
            setPayment(res.data.plan_list[res.data.plan_list.length-2])
            setCardList(res.data.card_list);
            setCardType(undefined);
        }
    }

    const setData = (data:any) =>{
        if(data?.phone_volume_price !== undefined && data?.phone_volume_price.length !== 0){
            setSize(data?.phone_volume_price[0]);
        }
        setColor(data?.phone_colors[0]);
        setNowUse({...data.phone_support_telco[0],...TEL_LIST[data.phone_support_telco[0].telco_id]});
    }

    // 공시지원금 가져오기
    const getDiscount = async() =>{
        const res = await apiGetDiscount(nowUse?.telco_id,info.phone_code,payment.plan_code,buyType);
        if(res.result){
            setDiscountObj(res.data);
        }
    }
    
    return (
        <MaxWidthWrap>
            <TitleWrap>
                <BasicText fontSize={26} mFontSize={22} fontWeight={700}>{info?.phone_name ?? info?.phone_model} {size === undefined ? '' : size?.volume_spec}{size === undefined ? '' : String(size?.volume_spec).length < 3 ? 'TB' : 'GB'}</BasicText>
                <span>{info?.phone_network}</span>
            </TitleWrap>
            <Wrap>
                <PartWrap>
                    <SelectPart 
                        info={info}
                        size={{size,setSize}}
                        color={{color,setColor}}
                        use={{use,setUse}}
                        nowUse={{get:nowUse,set:setNowUse}}
                        payment={{list:paymentList,get:payment,set:setPayment}}
                        discountType={{get:discountType,set:setDiscountType,setDiscount:setDiscount}}
                        buyType={{get:buyType,set:setBuyType}}
                        card={{list:cardList,get:cardType,set:setCardType}}
                        discountObj={discountObj}
                    />
                </PartWrap>
                <ResultWrap>
                    <ResultPart id={info?.phone_id} phone_is_revervation={info?.phone_is_revervation} price={price} discountType={discountType} discount={discount} payment={payment} buyType={buyType} imDiscount={Number(info?.phone_im_discount ?? 0)} cardType={cardType} userPrice={userPrice} setUserPrice={setUserPrice}/>
                </ResultWrap>
                <PartWrap>
                    <ContentPart info={info} />
                </PartWrap>
            </Wrap>
        </MaxWidthWrap>
    )
}

const TitleWrap = styled(Row)`
    gap: 10px;
    & span{
        color: #000;
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #000;
        border-radius: 4px;
        padding: 2px;
    }
`;
const Wrap = styled(Row)`
    flex-wrap: wrap;
    align-items: start;
    gap: 30px;
    margin-top: 12px;
    margin-bottom: 50px;
`;

const PartWrap = styled.div`
    width: 100%;
    max-width: 740px;
    &:nth-last-of-type(1){
        margin-top: 60px;
    }
    @media ${addTheme.media.l}{
        max-width: 100%;
    }
`;

const ResultWrap = styled.div`
    width: 100%;
    max-width: 480px;
    @media ${addTheme.media.l}{
        max-width: 100%;
    }
`;