import React from 'react'
import styled from 'styled-components'
import { InputWrap, Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { numberPrice, numberWithComma } from '../../../../utils/format/number';
import { onlyNumber } from '../../../../utils/format/string';

export default function ResultPriceItem({price,discountType,discount,cardDiscount,imDiscount,result,userPrice,setUserPrice,resResult}:any) {
    const userPriceChange = (val:any) =>{
        const numVal = Number(val);
        if(numVal <= result){
            setUserPrice(val);
        }else{
            setUserPrice(result)
        }
    }
    return (
        <Wrap>
            <ItemWrap>
                <BasicText color='#000' fontSize={19} mFontSize={16}>출고가</BasicText>
                <BasicText color='#000' fontSize={19} mFontSize={16} fontWeight={700}>{numberWithComma(price ?? '0')}원</BasicText>
            </ItemWrap>
            <ItemWrap>
                <BasicText color='#000' fontSize={19} mFontSize={16}>추가할인</BasicText>
                <InputWrap w='auto' max='110px' padding='6px' radius='4px'>
                    <input value={numberWithComma(userPrice)} onChange={(e:any)=>userPriceChange(onlyNumber(e))} placeholder='0' style={{textAlign:'right'}} />
                </InputWrap>
            </ItemWrap>
            {discountType === '1' &&
                <ItemWrap>
                    <BasicText color='#000' fontSize={19} mFontSize={16}>공시지원할인</BasicText>
                    <BasicText color='#000' fontSize={19} mFontSize={16} fontWeight={700}>- {numberWithComma(discount)}원</BasicText>
                </ItemWrap>
            }
            {!(imDiscount === undefined || imDiscount == '0') &&
                <ItemWrap>
                    <BasicText color='#000' fontSize={19} mFontSize={16}>사과나무컴퍼니 할인</BasicText>
                    <BasicText color='#000' fontSize={19} mFontSize={16} fontWeight={700}>- {numberWithComma(imDiscount ?? '0')}원</BasicText>
                </ItemWrap>
            }
            {!(cardDiscount === undefined || cardDiscount == '0') &&
                <ItemWrap>
                    <BasicText color='#000' fontSize={19} mFontSize={16}>제휴 카드 할인</BasicText>
                    <BasicText color='#000' fontSize={19} mFontSize={16} fontWeight={700}>- {numberWithComma(cardDiscount ?? '0')}원</BasicText>
                </ItemWrap>
            }
            <ResultItemWrap>
                <BasicText color='#fff' fontSize={20} mFontSize={17}>할부원금</BasicText>
                <BasicText color='#fff' fontSize={20} mFontSize={17} fontWeight={700}>{numberWithComma(resResult)}원</BasicText>
            </ResultItemWrap>
            {discountType === '2' &&
                <ItemWrap>
                    <BasicText color='#000' fontSize={19} mFontSize={16}>선택약정할인</BasicText>
                    <BasicText color='#000' fontSize={19} mFontSize={16} fontWeight={700}>- {numberWithComma(discount)}원</BasicText>
                </ItemWrap>
            }
        </Wrap>
    )
}

const Wrap = styled(Row)`
    border: 1px solid #d2d2d2;
    padding: 15px;
    background-color: #fff;
    margin-top: 20px;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
`;
const ItemWrap = styled(RowBetween)``;
const ResultItemWrap = styled(RowBetween)`
    padding: 10px 20px;
    background-color: ${addTheme.main};;
    &>p:nth-last-of-type(1){
        position: relative;
    }
    &>p:nth-last-of-type(1)::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 12px solid rgba(255, 255, 255, 0.1);
    }
`;