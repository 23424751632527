import React, { useEffect } from 'react'
import { SEND_BRIDGE } from '../../../../data/event_bridge';
import { useDispatch } from 'react-redux';
import { setAdminUser } from '../../../../store/common/adminUser';

export default function AdmWebviewPage() {
    const dispatch = useDispatch();

    useEffect(()=>{
        messageEvent();
        const webview = SEND_BRIDGE('INIT');
    },[])

    const messageEvent = async() =>{
        if(window.ReactNativeWebView === undefined) return;
        const userAgent = navigator.userAgent || navigator.vendor;
        const isIOS = /iPad|iPhone|iPod/.test(userAgent);
        if(isIOS){
            //ios
            await window.addEventListener("message", async(e:any) => {
                const data = JSON.parse(e.data);
                if(data.action === 'INIT'){
                    dispatch(setAdminUser({
                        webview: true,
                    }))
                }
            })
        }else{
            // 안드로이드
            await document.addEventListener("message", async(e:any) => {
                const data = JSON.parse(e.data);
                if(data.action === 'INIT'){
                    dispatch(setAdminUser({
                        webview: true,
                    }))
                }
            })
        }
    }

    return (
        <></>
    )
}
