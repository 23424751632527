import React, { useEffect, useState } from 'react'
import BasicText from '../../../basic/BasicText';
import { Center, Row, RowBetween } from '../../../../style/basic/commonStyle';
import styled from 'styled-components';
import { IoMenu } from "react-icons/io5";
import { addTheme } from '../../../../style/addTheme';
import { ADM_MENU_LIST } from '../../../../data/menu';
import { useLocation, useNavigate } from 'react-router';
import { IoClose } from "react-icons/io5";
import { useMediaQuery } from '@mui/material';

export default function WebviewHeaderPart({GoHistory,logout,setPassChangeOpen}:any) {
    const navigate = useNavigate();
    const location = useLocation();
    const mediaQuery = useMediaQuery(addTheme.media.m);
    const mQuery = useMediaQuery(addTheme.media.s);
    const [menuOnIdx,setMenuOnIdx] = useState(0);
    const [open,setOpen] = useState(false);

    useEffect(()=>{
        if(!mediaQuery){
            document.body.style.overflow = 'auto';
            setOpen(false);
        }
    },[mediaQuery]);

    useEffect(()=>{
        if(open){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'auto';
        }
    },[open]);

    useEffect(()=>{
        if(location.pathname === '/adm'){
            setMenuOnIdx(0);
            return;
        }
        ADM_MENU_LIST.map((data:any,index:any)=>{
            const chk = location.pathname.includes(data.path);
            if(chk){
                setMenuOnIdx(index+1);
            }
        })
    },[location.pathname]);

    const menuClick = (data:any,action?:any) =>{
        if(action){
            action();
        }else{
            GoHistory(data.router);
        }
        setOpen(false);
    }
    return (
        <Wrap>
            <BasicText color='#fff' fontSize={18} fontWeight={700} onClick={() => GoHistory('/adm')}>사과나무</BasicText>
            <MenuWrap>
                <IoMenu size={24} color='#fff' onClick={()=>setOpen(true)}/>
                {open &&
                    <MenuOpenWrap>
                        <MenuContentWrap>
                            <IoClose size={24} color='#fff' onClick={()=>setOpen(false)}/>
                            <InnerMenuContentWrap>
                                <MenuListWrap>
                                    {ADM_MENU_LIST.map((data:any,index:any)=>{
                                        return(
                                            <MenuItem state={menuOnIdx-1 === index} key={`webview-menu-${index}`} onClick={()=>menuClick(data)} >{data.name}</MenuItem>
                                        )
                                    })}
                                </MenuListWrap>
                                <MenuListWrap>
                                    <BasicText color='#fff' onClick={()=>menuClick('',setPassChangeOpen(true))}>비밀번호 변경</BasicText>
                                    <BasicText color='#fff' onClick={()=>menuClick('',logout)}>로그아웃</BasicText>
                                </MenuListWrap>
                            </InnerMenuContentWrap>
                        </MenuContentWrap>
                        <MenuBgWrap onClick={()=>setOpen(false)} />
                    </MenuOpenWrap>
                }
            </MenuWrap>
        </Wrap>
    )
}

const Wrap = styled(RowBetween)`
    position: fixed;
    top: 0;
    left: 0;
    padding: 15px 20px;
    background-color: ${addTheme.adminColor};
    z-index: 9999;
`;

const MenuWrap = styled(Center)`
    position: relative;
    & svg{
        cursor: pointer;
    }
`;

const MenuOpenWrap = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
const MenuContentWrap = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    max-width: 280px;
    background-color: ${addTheme.adminColor};
    &>svg{
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }
`;

const InnerMenuContentWrap = styled(Row)`
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;
const MenuListWrap = styled(Row)`
    flex-direction: column;
    gap: 20px;
    align-items: start;
    padding: 15px;
    margin-top: 60px;
    z-index: 5;
`;
const MenuItem = styled.div<{state:boolean}>`
    font-size: 18px;
    color: #fff;
    position: relative;
    padding-left: 10px;
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        background-color: #fff;
        display: ${({state}) => state ? 'block' : 'none'};
    }
`;
const MenuBgWrap = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
`;