import { axiosConfigInstance } from "./config"

// 핸드폰 리스트
export const apiGetPhList = async(page:any,count:any,phone_type?:any) =>{
    try {
        const res = await axiosConfigInstance('/api/product/phone').post('',{
            method: 'getPhoneInfoList',
            params:{
                page,count,phone_type
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 핸드폰 디테일
export const apiGetPhDetail = async(user_id:any,phone_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/product/phone').post('',{
            method: 'getPhoneInfoDetail',
            params:{
                user_id,phone_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 공시지원금
export const apiGetDiscount = async(telco_id:any,phone_code:any,plan_code:any,period_month:any) =>{
    try {
        const res = await axiosConfigInstance('/api/product/calculation').post('',{
            method: 'getPhoneProductCalculation',
            params:{
                telco_id,phone_code,plan_code,period_month
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 할인 방법/ 제휴카드
export const apiGetDiscountCard = async(phone_id:any,telco_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/discount/info').post('',{
            method: 'getDiscountInfoDetail',
            params:{
                phone_id,telco_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 제휴 카드 리스트 가져오기
export const apiGetDiscountCardList = async(telco_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/discount/info').post('',{
            method: 'getDiscountCardList',
            params:{
                telco_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문신청
export const apiSetOrder = async(discount_id:any, volume:any, color:any, discount_type:any, payment_months:any, dis_card_id:any, request_comment:any, user_name:any, user_birth:any, user_type:any, user_phone_number:any, user_email:any, user_password:any, user_post_code:any, user_address:any, user_address_detail:any, guard_name:any, guard_birth:any, guard_phone_number:any, guard_relation:any, open_phone_number:any, order_type:any, user_discount:any) =>{
    try {
        const res = await axiosConfigInstance('/api/order/phone').post('',{
            method: 'submitPhoneOrder',
            params:{
                discount_id,
                volume,
                color,
                discount_type,
                payment_months,
                dis_card_id,
                request_comment,
                user_name,
                user_birth,
                user_type,
                user_phone_number,
                user_email,
                user_password,
                user_post_code,
                user_address,
                user_address_detail,
                guard_name,
                guard_birth,
                guard_phone_number,
                guard_relation,
                open_phone_number,
                order_type,
                user_discount
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}