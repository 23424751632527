import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MaxWidthWrap, Row, RowBetween } from '../../../../style/basic/commonStyle';
import { MENU_LIST } from '../../../../data/menu';
import { useLocation, useNavigate } from 'react-router';
import { addTheme } from '../../../../style/addTheme';
import { LuSearch, LuUser } from "react-icons/lu";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import MoMenuPart from '../../../layout/part/MoMenuPart';
import SearchPart from '../../../layout/part/SearchPart';
import { Fade, Menu, MenuItem } from '@mui/material';

export default function HeaderPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [menuState,setMenuState] = useState(0);
    const [open,setOpen] = useState(false);
    const [anchorEl,setAnchorEl] = useState<any>(null);
    const userOpen = Boolean(anchorEl);

    useEffect(()=>{
        if(location.pathname === '/'){
            setMenuState(0);
        }else if(location.pathname.includes('/detail')){
            setMenuState(99);
        }else{
            MENU_LIST.map((data:any,index:any)=>{
                if(location.pathname.includes(data.path)){
                    setMenuState(index);
                }
            })
        }
    },[location.pathname]);

    const menuClick = (router:string) =>{
        navigate(router);
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const userMenuClick = (name:string) =>{
        handleClose();
        navigate(name);
    }
    
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <MenuWrap>
                        <LogoWrap onClick={()=>menuClick('')}>
                            <img src='/img/asset/logo-o.png'></img>
                        </LogoWrap>
                        <MenuContentWrap>
                            {MENU_LIST.map((data:any,index:any)=>{
                                return(
                                    <MenuTitle onClick={()=>menuClick(data.router)} menuState={index === menuState} key={`menu-${index}`}>{data.name}</MenuTitle>
                                )
                            })}
                        </MenuContentWrap>
                    </MenuWrap>
                    <IconWrap>
                        <SearchWrap>
                            <LuSearch onClick={()=>setOpen(true)} size={18}/>
                        </SearchWrap>
                        <UserWrap onClick={handleClick}>
                            <LuUser size={22}/>
                            {userOpen ? 
                                <IoIosArrowUp size={18} /> :
                                <IoIosArrowDown size={18}/>
                            }
                        </UserWrap>
                        <Menu anchorEl={anchorEl} open={userOpen} onClose={handleClose} TransitionComponent={Fade}>
                            {/* <MenuItem sx={{fontSize:'15px'}} onClick={()=>userMenuClick('/myPage')}>인터넷 내역</MenuItem> */}
                            <MenuItem sx={{fontSize:'15px'}} onClick={()=>userMenuClick('/myPagePh')}>핸드폰 내역</MenuItem>
                        </Menu>
                        <MoMenuWrap>
                            <MoMenuPart menuState={menuState} />
                        </MoMenuWrap>
                    </IconWrap>
                </InnerWrap>
            </MaxWidthWrap>
            <SearchPart open={open} setOpen={setOpen} />
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled(RowBetween)`
    padding: 10px 0;
`;
const MenuWrap = styled(Row)`
    width: auto;
    gap: 25px;
`;

const LogoWrap = styled.div`
    & img{
        height: 48px;
    }
`;
const MenuContentWrap = styled(Row)`
    gap: 20px;
    width: auto;
    @media ${addTheme.media.s}{
        display: none;
    }
`;
const MenuTitle = styled.div<{menuState:boolean}>`
    cursor: pointer;
    font-size: 18px;
    color: ${({menuState}) => menuState ? addTheme.main : '#000'};
    position: relative;
    padding: 10px 0;
`;

const IconWrap = styled(Row)`
    width: auto;
    gap: 10px;
`;

const SearchWrap = styled.div`
    cursor: pointer;
`;
const UserWrap = styled(Row)`
    width: auto;
    cursor: pointer;
`;

const MoMenuWrap = styled.div`
    display: none;
    @media ${addTheme.media.m}{
        display: block;
    }
`;