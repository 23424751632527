import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { MaxWidthWrap } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { apiGetBanner } from '../../../../api/api_banner';
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";

const CustomArrow = ({ className, style, onClick, direction }:any) => {
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            {direction === 'next' ? <FaChevronRight size={28} color='#656565'/> : <FaChevronLeft size={28} color='#656565'/>}
        </div>
    );
};

export default function PhoneBannerPart() {
    const [list,setList] = useState<any>([]);
    const [drag,setDrag] = useState(false);

    useEffect(()=>{
        getList();
    },[])
    const getList = async() =>{
        const res = await apiGetBanner('1',1,50);
        if(res.result){
            setList(res.data);
            // setList([{banner_img_web:'https://hjpd.nvpworld.com/banner_image/home-banner01.png',banner_img_mo:'https://hjpd.nvpworld.com/banner_image/home-banner01.png'},
            //     {banner_img_web:'https://hjpd.nvpworld.com/banner_image/home-banner01.png',banner_img_mo:'https://hjpd.nvpworld.com/banner_image/home-banner01.png'},
            //     {banner_img_web:'https://hjpd.nvpworld.com/banner_image/home-banner01.png',banner_img_mo:'https://hjpd.nvpworld.com/banner_image/home-banner01.png'},
            //     {banner_img_web:'https://hjpd.nvpworld.com/banner_image/home-banner01.png',banner_img_mo:'https://hjpd.nvpworld.com/banner_image/home-banner01.png'},
            // ])
        }
    }
    
    const handleBeforeChange = useCallback(() => {
        if(setDrag){
            setDrag(true);
        }
    }, []); 
    const handleAfterChange = useCallback((i: number) => {
        if(setDrag){
            setDrag(false);
        }
    }, []);

    const setting = {
        infinite: true,
        speed:1000,
        touchThreshold : 100,
        autoplaySpeed: 5000,
        autoplay:true,
        arrows:true,
        pauseOnHover : true,
        beforeChange: handleBeforeChange,    
        afterChange: handleAfterChange,
        dots: true,
        nextArrow: <CustomArrow direction="next" />,
        prevArrow: <CustomArrow direction="prev" />,
    }

    const itemClick = (link:any) =>{
        if(!drag){
            window.open(link);
        }
    }

    return (
        <Wrap>
            {list === undefined || list.length === 0 ?
                <></> :
                list.length === 1 ?
                    <ImgWrap >
                        <img className='banner-pc-img' src={list[0].banner_img_web} />
                        <img className='banner-m-img' src={list[0].banner_img_mo} />
                    </ImgWrap> :
                    <Slider {...setting}>
                        {list.map((data:any,index:any)=>{
                            return(
                                <ImgWrap key={`banner-${index}`} onClick={()=>itemClick(data.banner_link_url)}>
                                    <img className='banner-pc-img' src={data.banner_img_web} />
                                    <img className='banner-m-img' src={data.banner_img_mo} />
                                </ImgWrap>
                            )
                        })}
                    </Slider>
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    & .slick-track{
        div:focus-visible{
            outline: none;
        }
    }
    & .slick-dots{
        bottom: 20px;
    }
    & .slick-dots li{
        height: 5px;
        width: 5px;
        border-radius: 5px;
        overflow: hidden;
        transition: 0.3s;
        margin: 0 3px;
    }
    & .slick-dots li.slick-active{
        width: 14px;
    }
    & .slick-dots li button:before{
        content: '';
        background-color: #fff;
        width: 100%;
        height: 100%;
    }
    & .slick-dots li.slick-active button:before{
        opacity: 1;
    }
    & .slick-prev{
        z-index: 5;
        left: 20px;
        ::before{
            content: none;
        }
    }
    & .slick-next{
        right: 20px;
        ::before{
            content: none;
        }
    }
`;
const ImgWrap = styled.div`
    width: 100%;
    & img{
        width: 100%;
        :focus-visible{
            outline: none !important;
        }
    }
    & .banner-m-img{
        display: none;
    }

    @media ${addTheme.media.s}{
        & .banner-m-img{
            display: block;
        }
        & .banner-pc-img{
            display: none;
        }
    }
`;
