import { axiosConfigInstance } from "./config"

// link 가져오기
export const apiAdmGetLink = async(token:string,admin_id:any,) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/config',token).post('',{
            method: 'getAdminConfigInfo',
            params: {
                admin_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// link 수정
export const apiAdmSetLinkUpdate = async(token:string,admin_id:any,info:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/config',token).post('',{
            method: 'adminModifyConfigInfo',
            params: {
                admin_id,
                ...info
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 상담 비/활성화
export const apiAdmSetCounseling = async(token:string,admin_id:any,status:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/config',token).post('',{
            method: 'adminChangeConsulationStatus',
            params: {
                admin_id,
                status
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}