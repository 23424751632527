import React from 'react'
import styled from 'styled-components'
import { MaxWidthWrap, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { useGlobalCompanyInfo } from '../../../../utils/custom-hooks/common/useGlobalCompanyInfo';

export default function PhoneBtnPart() {
    const globalCompany = useGlobalCompanyInfo();
    const phoneClick = () =>{
        if(globalCompany['config_company_call_number'] === undefined) return;
        window.location.href = 'tel:' + globalCompany['config_company_call_number'];
    }
    const kakaoClick = () =>{
        if(globalCompany['config_social_kakao_link'] === undefined) return;
        window.open(globalCompany['config_social_kakao_link']);
    }
    return (
        <Wrap>
            <MaxWidthWrap>
                <BtnWrap>
                    <BtnItem bg={addTheme.kakaoColor} onClick={kakaoClick}>
                        <BasicText fontSize={addTheme.mFontSize+12} mFontSize={addTheme.mFontSize+4}>사과나무 카카오톡 상담</BasicText>
                        <img src='/img/logo/kakao.png'></img>
                    </BtnItem>
                    <BtnItem bg={'#fb4206'} onClick={phoneClick}>
                        <BasicText fontSize={addTheme.mFontSize+12} mFontSize={addTheme.mFontSize+4} color='#fff'>사과나무 전화 상담</BasicText>
                        <img src='/img/asset/mbtn02.png'></img>
                    </BtnItem>
                </BtnWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const BtnWrap = styled(RowBetween)`
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
        gap: 15px;
    }
`;
const BtnItem = styled(RowBetween)<{bg?:string}>`
    width: 48%;
    background-color: ${({bg}) => bg ?? '#fff'};
    height: 65px;
    padding: 10px 20px;
    border-radius: 6px;
    & img{
        height: 40px;
    }
    @media ${addTheme.media.s}{
        width: 100%;
        & img{
            height: 32px;
        }
    }
`;