import React from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import PhoneItem from './PhoneItem';
import { addTheme } from '../../../../style/addTheme';

export default function PhoneListPartItem({title,mt,list,list2,count}:any) {
    return (
        <Wrap>
            <Center style={{marginTop:mt ?? 0}}>
                <BasicText fontWeight={700} fontSize={addTheme.mFontSize+16} mFontSize={addTheme.mFontSize+10}>{title}</BasicText>
            </Center>
            <ContentWrap>
                {list === undefined || list.length === 0 ?
                    <></> :    
                    list.map((data:any,index:any)=>{
                        if((index+1)>count) return;
                        return(
                            <PhoneItem key={`phone-${index}`} data={data} />
                        )
                    })
                }
                {list2 === undefined || list2.length === 0 ?
                    <></> :    
                    list2.map((data:any,index:any)=>{
                        if((index+1)>count) return;
                        return(
                            <PhoneItem key={`phone-i-${index}`} data={data} />
                        )
                    })
                }
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled(Row)`
    margin-top: 35px;
    gap: 20px;
    flex-wrap: wrap;
    &>div{
        width: calc(50% - 12px);
    }
    @media ${addTheme.media.sx}{
        &>div{
            width: 100%;
        }
    }
`;