import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import { apiGetPhList } from '../../../api/api_phone';
import PhoneItem from '../../type02/phone/item/PhoneItem';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';

export default function IphonePage() {
    const [list,setList] = useState([]);

    useEffect(()=>{
        getList();
    },[])

    const getList = async() =>{
        const res = await apiGetPhList(1,80);
        if(res.result){
            const i = res.data.filter((data:any)=>data.phone_type === '2');
            setList(i);
        }
    }
    return (
        <Wrap>
            <MaxWidthWrap>
                <Center>
                    <BasicText fontWeight={700} fontSize={addTheme.mFontSize+16} mFontSize={addTheme.mFontSize+10}>아이폰</BasicText>
                </Center>
                <ContentWrap>
                    {list === undefined || list.length === 0 ?
                        <></>:
                        list.map((data:any,index:any)=>{
                            return(
                                <PhoneItem key={`g-${index}`} data={data} />
                            )
                        })
                    }
                </ContentWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 0px 0 50px  ;
`;

const ContentWrap = styled(Row)`
    margin-top: 35px;
    gap: 20px;
    flex-wrap: wrap;
    &>div{
        width: calc(50% - 12px);
    }
    @media ${addTheme.media.sx}{
        &>div{
            width: 100%;
        }
    }
`;