import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap } from '../../../../style/basic/commonStyle';
import { apiGetPhList } from '../../../../api/api_phone';
import PhoneListPartItem from '../item/PhoneListPartItem';
import { addTheme } from '../../../../style/addTheme';

const sortAction = (list:any) =>{
    const sortRes = [...list].sort((a:any,b:any)=>{
        const totalA = Number(a.discount_change_price) + Number(a.phone_im_discount);
        const totalB = Number(b.discount_change_price) + Number(b.phone_im_discount);
        return totalB - totalA;
    })
    return sortRes
}

export default function PhoneListPart() {
    const [listG,setSetListG] = useState([]);
    const [listI,setSetListI] = useState([]);
    const [listGSort,setListGSort] = useState<any>([]);
    const [listISort,setListISort] = useState<any>([]);
    const [count,setCount] = useState<any>({
        g: 4,
        i: 4,
        gSort: 4,
        iSort: 4,
    })

    useEffect(()=>{
        getList();
    },[])

    const getList = async() =>{
        const res = await apiGetPhList(1,60);
        if(res.result){
            const g = res.data.filter((data:any)=>data.phone_type === '1');
            const i = res.data.filter((data:any)=>data.phone_type === '2');
            setSetListG(g);
            setSetListI(i);
            const gSort = sortAction(g);
            const iSort = sortAction(i);
            setListGSort(gSort);
            setListISort(iSort);
        }
    }

    const btnEvent = (type:string) =>{
        setCount((obj:any)=>{
            return {
                ...obj,
                [type]: obj[type]+2
            }
        })
    }
    
    return (
        <Wrap>
            <MaxWidthWrap>
                <PhoneListPartItem title={'갤럭시'} list={listG} count={count.g} mt='100px'/>
                {count.g <= listG.length &&
                    <BtnWrap>
                        <BtnItem onClick={()=>btnEvent('g')}>더보기</BtnItem>
                    </BtnWrap>
                }
                <PhoneListPartItem title={'아이폰'} list={listI} count={count.i} mt='100px'/>
                {count.i <= listI.length &&
                    <BtnWrap>
                        <BtnItem onClick={()=>btnEvent('i')}>더보기</BtnItem>
                    </BtnWrap>
                }
                <PhoneListPartItem title={'갤럭시 최대 할인'} list={listGSort} count={count.gSort} mt='100px'/>
                {count.gSort <= listGSort.length &&
                    <BtnWrap>
                        <BtnItem onClick={()=>btnEvent('gSort')}>더보기</BtnItem>
                    </BtnWrap>
                }
                <PhoneListPartItem title={'아이폰 최대할인'} list={listISort} count={count.iSort} mt='100px'/>
                {count.iSort <= listISort.length &&
                    <BtnWrap>
                        <BtnItem onClick={()=>btnEvent('iSort')}>더보기</BtnItem>
                    </BtnWrap>
                }
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    padding-bottom: 80px;
`;
const BtnWrap = styled(Center)`
    margin-top: 50px;
`;
const BtnItem = styled.button`
    background-color: ${addTheme.color};
    width: 180px;
    height: 48px;
    border-radius: 4px;
    color: #fff;
`;