
export function SEND_BRIDGE(action) {
    if (window.ReactNativeWebView) {
        const methodFormat = {
            action: action,
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(methodFormat));
        return true;
    }
    return false;
}
