import { Dialog, DialogContent } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../../basic/BasicText';
import { Center, Row } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import { apiAdmPassChange } from '../../../../api/api_adm_login';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';

export default function OpenPasswordChangePart({open,setOpen}:any) {
    const ref = useRef<any>(null);
    const [id,setId] = useState('');
    const [pw,setPw] = useState('');
    const [newPw,setNewPw] = useState('');
    const [newPwChk,setNewPwChk] = useState('');
    const adminUser = useAdminUser();

    useEffect(()=>{
        if(open){
            reset();
        }
    },[open]);

    const reset = () =>{
        setId('')
        setPw('');
        setNewPw('');
        setNewPwChk('');
    }

    const close = () =>{
        setOpen(false);
    }

    const passChange = async(e:any)=>{
        e.preventDefault();
        if(newPw === newPwChk){
            const res = await apiAdmPassChange(adminUser.admin_token,adminUser.admin_id,id,pw,newPw,newPwChk);
            if(res.result){
                alert('비밀번호가 변경되었습니다');
                reset();
                close();
            }
        }else{
            alert('새로운 비밀번호가 다릅니다.')
        }
    }

    return (
        <Dialog
            ref={ref}
            open={open} 
            onClose={close}
            sx={{
                '.MuiPaper-root':{margin:'8px', maxWidth:'400px',width:'100%',borderRadius: '6px'}
            }}
        >
            <DialogContent dividers={true}>
                <Wrap>
                    <BasicText marginTop={1} fontSize={18} align='center' fontWeight={700}>비밀번호 변경</BasicText>
                    <InputWrap onSubmit={passChange}>
                        <InputItem>
                            <input placeholder='아이디' value={id} onChange={(e:any)=>setId(e.target.value)} required/>
                        </InputItem>
                        <InputItem>
                            <input type='password' placeholder='현재 비밀번호' value={pw} onChange={(e:any)=>setPw(e.target.value)} required/>
                        </InputItem>
                        <InputItem>
                            <input type='password' placeholder='새로운 비밀번호' value={newPw} onChange={(e:any)=>setNewPw(e.target.value)} required/>
                        </InputItem>
                        <InputItem>
                            <input type='password' placeholder='새로운 비밀번호 확인' value={newPwChk} onChange={(e:any)=>setNewPwChk(e.target.value)} required/>
                        </InputItem>
                        <Center>
                            <BtnItem type='submit'>비밀번호 변경</BtnItem>
                        </Center>
                    </InputWrap>
                </Wrap>
            </DialogContent>
        </Dialog>
    )
}

const Wrap = styled.div``;
const InputWrap = styled.form`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 25px;
`;
const InputItem = styled(Row)`
    max-width: 285px;
    width: 100%;
    margin: 0 auto;
    &>input{
        border: 1px solid #ddd;
        padding: 12px 10px;
        border-radius: ${addTheme.round}px;
        width: 100%;
        font-size: 16px;
    }
`;

const BtnItem = styled.button`
    background-color: ${addTheme.adminColor};
    color: #fff;
    padding: 8px 16px;
    border-radius: 4px;
    margin-top: 20px;
`;