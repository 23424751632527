import React from 'react'
import SelectItem from './SelectItem'
import styled from 'styled-components'
import { Row } from '../../../../style/basic/commonStyle'
import BasicText from '../../../basic/BasicText'

export default function SelectColorItem({list,color}:any) {
    const clickAction = (data:any) =>{
        color.setColor(data);
    }
    return (
        <SelectItem title='색상'>
            <Wrap>
                {list === undefined || list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any)=>{
                        return (
                            <ItemWrap key={`color-${index}`} onClick={()=>clickAction(data)} state={data.color_hex === color?.color?.color_hex}>
                                <Item color={data.color_hex} />
                            </ItemWrap>
                        )
                    })
                }
            </Wrap>
            <BasicText color='#000' marginTop={0.2}>{color?.color?.color_name ?? ''}</BasicText>
        </SelectItem>
    )
}

const Wrap = styled(Row)`
    gap: 8px;
`;
const ItemWrap = styled.div<{state:boolean}>`
    width: 46px;
    height: 46px;
    border: 1px solid ${({state}) => state ? '#000' : '#d3d3d3'};
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;
`;

const Item = styled.div<{color:string}>`
    border: 1px solid #fff;
    width: 100%;
    height: 100%;
    background-color: ${({color}) => color ?? '#fff'};
    border-radius: 3px;
`;