import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { useNavigate, useParams } from 'react-router';
import QnaInquiryPart from '../part/QnaInquiryPart';
import QnaListPart from '../part/QnaListPart';
import { apiGetQnaCate } from '../../../api/api_qna';

export default function QnaPage({tab}:any) {
    const navigate = useNavigate();
    const [faqCateList,setFaqCateList] = useState<any>([]);
    const tabClick = (type:any) =>{
        navigate(`/${type}`);
    }

    useEffect(()=>{
        getCate();
    },[])

    const getCate = async() =>{
        const res = await apiGetQnaCate(1,50);
        if(res.result){
            setFaqCateList(res.data);
        }
    }

    return (
        <MaxWidthWrap>
            <Wrap>
                <TitleWrap>
                    <BasicText fontSize={38} mFontSize={24}  fontWeight={700} spColor={addTheme.color}><span>사과나무</span> 고객센터</BasicText>
                </TitleWrap>
                <SelectWrap>
                    <SelectBtnItem state={tab === '1'} onClick={()=>tabClick('faq')}>자주하는 질문</SelectBtnItem>
                    <SelectBtnItem state={tab === '2'} onClick={()=>tabClick('inquiry')}>1:1 문의</SelectBtnItem>
                </SelectWrap>
                <ContentWrap>
                    {tab === '1' &&
                        <QnaListPart faqCateList={faqCateList} />
                    }
                    {tab === '2' &&
                        <QnaInquiryPart />
                    }
                </ContentWrap>
            </Wrap>
        </MaxWidthWrap>
    )
}

const Wrap = styled.div`
`;
const TitleWrap = styled(Center)`
    
`;

const SelectWrap = styled(Center)`
    gap: 20px;
    margin-top: 30px;
`;
const SelectBtnItem = styled(Center)<{state:boolean}>`
    background-color: ${({state}) => state ? addTheme.color : '#f0f0f0'};
    color: ${({state}) => state ? '#fff' : '#888'};
    width: 180px;
    height: 58px;
    cursor: pointer;
    border-radius: ${addTheme.round}px;
    font-size: 20px;
    @media ${addTheme.media.s}{
        width: 140px;
        height: 42px;
        font-size: 16px;
    }
`;

const ContentWrap = styled.div`
    width: 100%;
    max-width: 1080px;
    margin: 40px auto 0;

`;
