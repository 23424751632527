import { createGlobalStyle, css } from 'styled-components';
import StyledReset from 'styled-reset';
import { MyReset } from './my-reset';

const layout = css`
  :root {
    color-scheme: light only;
  }
  html, body, #root {
    height: 100%;
    overscroll-behavior-y: none;
  }
  
  #root {
    display: flex;
    flex-direction: column;
  }
`;

const reset = css`
  ${StyledReset};
  ${MyReset};
`;

export const notSelect = css`
  -webkit-user-select: none;
  -moz-user-select: none;
`;

export const GlobalStyle = createGlobalStyle`

  ${reset};
  ${layout};

  a {
    color: inherit;
    display: block;
  }
  
  body {
  // padding: 10px;
    ${notSelect}
    background-repeat: no-repeat;
    font-family: "pretendard" !important;
  }
  input{
    :focus-visible{
      outline: none;
    }
  }
  textarea{
    :focus-visible{
      outline: none;
    }
  }
  @media (max-width: 500px){
    * {
      cursor: default !important;
    }
  }
`;

