import React from 'react'
import styled from 'styled-components'
import { addTheme } from '../../../../style/addTheme';
import { Center, Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { numberWithComma } from '../../../../utils/format/number';
import { useNavigate } from 'react-router';

export default function PhoneItem({data}:any) {
    const navigate = useNavigate();
    const price = data?.phone_volume_price === undefined || data?.phone_volume_price.length === 0 ? 0 : Number(data?.phone_volume_price[0].price ?? 0);
    const discount = Number(data?.discount_change_price ?? 0);
    const imDiscount = Number((data?.phone_im_discount === null ? 0 : data?.phone_im_discount ?? 0));
    const result = price - discount - imDiscount;
    const goDetail = () =>{
        navigate(`/detail/${data.phone_id}`);
    }

    return (
        <Wrap onClick={goDetail}>
            <ImgWrap>
                <img src={data?.phone_thumbnail}></img>
            </ImgWrap>
            <ContentWrap>
                <BasicText fontSize={24} mFontSize={18}>{data.phone_name === '' ? data.phone_model : data.phone_name}</BasicText>
                <BasicText fontSize={15} mFontSize={13} color='#828282' deco='line-through'>출고가 {numberWithComma(price)}원</BasicText>
                <BasicText fontSize={28} mFontSize={21} color={addTheme.color} fontWeight={700}>{data.phone_is_revervation === '1' ? '가격 문의' : `${numberWithComma(result)}원`}</BasicText>
                <StateWrap>
                    <BasicText align='center' color='#8d8d8d' fontSize={14}>{data?.phone_description}</BasicText>
                </StateWrap>
                <DiscountTextWrap>
                    <DiscountItemWrap>
                        <BasicText color='#b1b1b1' fontSize={16} mFontSize={15}>사과나무 할인</BasicText>
                        <BasicText color='#b1b1b1' fontSize={16} mFontSize={15}>{numberWithComma(imDiscount)}원</BasicText>
                    </DiscountItemWrap>
                    <DiscountItemWrap>
                        <BasicText color='#b1b1b1' fontSize={16} mFontSize={15}>공시지원할인</BasicText>
                        <BasicText color='#b1b1b1' fontSize={16} mFontSize={15}>{numberWithComma(discount)}원</BasicText>
                    </DiscountItemWrap>
                    <DiscountItemWrap>
                        <BasicText color='#b1b1b1' fontSize={16} mFontSize={15}>총 할인</BasicText>
                        <BasicText color='#b1b1b1' fontSize={16} mFontSize={15} spColor={addTheme.color}><span>{numberWithComma(discount + imDiscount)}원</span></BasicText>
                    </DiscountItemWrap>
                </DiscountTextWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    border-radius: 15px;
    border: 1px solid #eee;
    box-shadow: 0 0 8px rgba(0,0,0,0.2);
    padding: 15px;
    position: relative;
    gap: 15px;
    align-items: start;
    cursor: pointer;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
    }
`;

const ImgWrap = styled.div`
    width: 35%;
    text-align: center;
    &>img{
        width: 100%;
    }
    @media ${addTheme.media.s}{
        width: 100%;
        &>img{
            max-width: 200px;
        }
    }
`;
const ContentWrap = styled.div`
    width: 60%;
    padding: 15px;
    @media ${addTheme.media.s}{
        width: 100%;
        padding: 0px;
    }
`;

const StateWrap = styled(Center)`
    background-color: #f5f5f5;
    padding: 8px;
    border-radius: ${addTheme.round}px;
    margin-top: 12px;
`;

const DiscountTextWrap = styled.div`
    margin-top: 8px;
`;
const DiscountItemWrap = styled(RowBetween)`
    &:nth-last-of-type(1){
        padding-top: 5px;
        margin-top: 5px;
        border-top: 1px solid #dbdbdb;
    }
`;