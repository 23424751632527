import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { apiGetReviewList } from '../../../../api/api_review';
import PhoneReviewItem from '../item/PhoneReviewItem';
import { useNavigate } from 'react-router';
import ReviewDetailPart from '../../../review/part/ReviewDetailPart';

export default function PhoneReviewPart() {
    const navigate = useNavigate();
    const [list,setList] = useState<any>([]);
    const [item,setItem] = useState<any>();
    const [open,setOpen] = useState(false);

    useEffect(()=>{
        getList();
    },[])
    const getList = async() =>{
        const res = await apiGetReviewList(1,6);
        if(res.result){
            setList(res.data);
        }
    }
    const moreClick = () =>{
        navigate('/review');
    }
    const itemClick = (data:any) =>{
        setItem(data);
        setOpen(true);
    }
    return (
        <Wrap>
            <MaxWidthWrap>
                <Center>
                    <BasicText fontWeight={700} fontSize={addTheme.mFontSize+16} mFontSize={addTheme.mFontSize+10}>사과나무 리얼 구매 리뷰</BasicText>
                </Center>
                <ContentWrap>
                    {list === undefined || list.length === 0 ?
                        <></> :
                        list.map((data:any,index:any)=>{
                            return(
                                <PhoneReviewItem key={`review-${index}`} data={data} itemClick={()=>itemClick(data)}/>
                            )
                        })
                    }
                </ContentWrap>
                <Center style={{marginTop:'30px'}}>
                    <MoreBtn onClick={moreClick}>구매후기 더 보기</MoreBtn>
                </Center>
            </MaxWidthWrap>
            <ReviewDetailPart open={open} setOpen={setOpen} item={item} setItem={setItem}/>
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 60px 0;
`;

const ContentWrap = styled(Row)`
    gap: 25px;
    margin-top: 20px;
    flex-wrap: wrap;
    &>div{
        width: calc(33% - 20px);
    }
    @media ${addTheme.media.s}{
        &>div{
            width: calc(50% - 20px);
        }
    }
`;

const MoreBtn = styled.button`
    width: 180px;
    height: 46px;
    background-color: ${addTheme.moreBg};
    color: ${addTheme.moreColor};
    border-radius: 6px;
`;