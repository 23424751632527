import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center, ContentWrap, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { fileTypeRes } from '../../../utils/format/string';
import SelectItem from '../../common/item/SelectItem';
import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import StarPart from '../../common/part/StarPart';
import { apiSetReview } from '../../../api/api_review';

export default function ReviewCreatePage() {
    const fileRef = useRef<any>(null);
    const [chk,setChk] = useState(false);
    const [info,setInfo] = useState({
        review_title: '',
        review_content: '',
        review_image: '',
        review_rate: '',
        review_user_name: '',
        fileName: '',
    })

    // input data
    const valueChange = (name:string,val:any)=>{
        setInfo((obj:any)=>({...obj,[name]:val}));
    }

    // 파일 선택 trigger
    const imgUploadClick = () =>{
        if(fileRef){
            fileRef.current.click();
        }
    }

    // 이미지 선택
    const fileChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const fileFormat = fileTypeRes(file.name);
            if(fileFormat === 'png' || fileFormat === 'jpg' || fileFormat === 'gif'){
                const reader = new FileReader();
                setInfo((obj:any)=>({...obj,fileName:file.name}))
                reader.onload = () => {
                    const result = reader.result;
                    setInfo((obj:any)=>({...obj,review_image: result}));
                };

                reader.readAsDataURL(file);
            }else{
                alert('확장자가 png, jpg인 이미지 파일만 가능합니다.');
                fileRef.current.value = null;
            }
        }
    }

    const createAction = async(e:any) =>{
        e.preventDefault();
        if(!chk){
            alert('개인정보 수집 및 이용을 동의해주세요.');
            return;
        }
        if(info.review_user_name === ''){
            alert('작성자를 입력해주세요.');
            return;
        }
        if(info.review_title === ''){
            alert('제목을 입력해주세요.');
            return;
        }
        if(info.review_rate === ''){
            alert('별점을 선택해주세요.');
            return;
        }
        const res = await apiSetReview(info);
        if(res.result){
            alert('후기를 작성했어요');
            location.reload();
        }else{
            alert(res.error);
        }
    }

    return (
        <MaxWidthWrap>
            <Wrap>
                <TitleWrap>
                    <BasicText fontSize={38} mFontSize={24} fontWeight={700} align='center' ls={1.5}>구매 후기 작성</BasicText>
                    <BasicText fontSize={20} mFontSize={15} align='center' marginTop={1}>핸드폰을 구매한 후기를 작성하여 주세요.</BasicText>
                </TitleWrap>
                <FormWrap onSubmit={createAction}>
                    <ContentWrap gap={15}>
                        <ItemWrap>
                            <BasicText spColor='red'>작성자 <span>*</span></BasicText>
                            <ItemInputWrap>
                                <input value={info.review_user_name} onChange={(e:any) => valueChange('review_user_name',e.target.value)} required placeholder='이름을 입력해주세요.'></input>
                            </ItemInputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText spColor='red'>제목 <span>*</span></BasicText>
                            <ItemInputWrap>
                                <input value={info.review_title} onChange={(e:any) => valueChange('review_title',e.target.value)} required placeholder='제목을 입력해주세요.'></input>
                            </ItemInputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText spColor='red'>별점 <span>*</span></BasicText>
                            <ItemInputWrap style={{border:'none', padding: '10px 0'}}>
                                <StarPart count={info.review_rate} setCount={(val:any)=>valueChange('review_rate',val)} size={20} colorText='#000'/>
                            </ItemInputWrap>
                        </ItemWrap>
                        <ItemWrap align='start'>
                            <BasicText>후기 내용</BasicText>
                            <div style={{width:'100%'}}>
                                <ItemInputWrap>
                                    <textarea value={info.review_content} onChange={(e:any) => valueChange('review_content',e.target.value)} rows={6} maxLength={1000}></textarea>
                                </ItemInputWrap>
                                <ImgBtnWrap onClick={imgUploadClick}>이미지 업로드</ImgBtnWrap>
                                <input ref={fileRef} type='file' style={{display:'none'}} accept='.png,.jpg' onChange={fileChange}></input>
                                {info.review_image === '' ?
                                    <></> :
                                    <FilePreviewWrap>
                                        <img src={info.review_image} alt='file-img'></img>
                                    </FilePreviewWrap>
                                }
                            </div>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText spColor='red'>개인정보 수집 및 이용 <span>*</span></BasicText>
                            <FormControlLabel control={<Checkbox sx={{color:addTheme.color,'&.Mui-checked': {color:addTheme.color}}} checked={chk} onChange={(e:any)=>setChk(e.target.checked)} /> } label={'동의'} />
                            {/* <label>
                                <input type='checkbox' onChange={(e:any)=>setChk(e.target.checked)} required></input> 동의
                            </label> */}
                        </ItemWrap>
                    </ContentWrap>
                    <BtnWrap type='submit'>작성완료</BtnWrap>
                </FormWrap>
            </Wrap>
        </MaxWidthWrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
`;
const TitleWrap = styled.div``;
const ItemWrap = styled(Row)<{align?:string}>`
    align-items: ${({align}) => align ?? 'center'};
    &>p{
        width: 185px;
        flex-shrink: 0;
    }
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
    }
`;
const FormWrap = styled.form`
    margin-top: 40px;
`;

const ItemInputWrap = styled(Row)`
    border: 1px solid #e6e6e6;
    padding: 10px 14px;
    border-radius: 6px;
    & input{
        width: 100%;
    }
    & textarea{
        width: 100%;
    }
`;

const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    margin-top: 15px;
`;

const FilePreviewWrap = styled.div`
    margin-top: 10px;
    width: 100%;
    max-width: 350px;
    &>img{
        width: 100%;
    }
`;

const BtnWrap = styled.button`
    width: 100%;
    background-color: ${addTheme.color};
    height: 52px;
    color: #fff;
    border-radius: 6px;
    margin-top: 50px;
`;